
// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
	.header-slogan {
		display: none;
	}

	section.section-welcome {
		.welcome-content {
			width: 100%;
		}
	}

	section.section-about {
		margin-top: 150px;
	}

	section.section-offer {
		.offer-flexbox {
			.flexbox-service-wrapper {
				.service-inner {
					.inner-text {
						letter-spacing: 3px;
						font-size: 15px;
						font-weight: 400;
					}

					&.contact {
						p {
							letter-spacing: 3px;
							font-size: 15px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}

	section.section-schiedel {
		padding-top: 100px;
	}

	section.section-news {
		margin-top: 100px;
		padding-top: 100px;
	}

	section.oferta-section-cta {
		.about-image {
			width: 320px;
		}

		.about-flexbox {
			.flexbox-description {
				p {
					width: 100%;
				}
			}
		}
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
	nav.section-header {
		z-index: 222;
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);

		.header-flexbox {
			border: none;

			.nav-button {
				display: flex;
				margin-right: -10px;
			}

			.flexbox-nav-wrapper {
				position: fixed;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				overflow-y: scroll;
				background: #fff;
				z-index: 999;
				opacity: 0;
				visibility: hidden;
				transition: all 0.3s;

				.nav-button-close {
					display: block;
				}

				.flexbox-nav-inner {
					display: flex;
					flex-direction: column;
					justify-content: center;
					min-height: 100%;
					padding: 50px;
					margin-right: 0;

					.flexbox-nav {
						flex-direction: column;
						text-align: center;

						li {
							margin-right: 0;

							a {
								color: #000;
								font-size: 17px;
								padding: 10px;

								&.active {
									color: @color1;
								}
							}

							&:hover {
								a {
									transform: none;
								}
							}

							&.li-mobile {
								display: block;

								ul {
									li {
										a {
											font-size: 15px;
											padding: 7px;
											color: #777;
										}
									}
								}
							}

							&.li-desktop {
								display: none;
							}

							&.highlight {
								a {
									padding: 10px;
									border: none;

									&.active {
										background: none;
										font-weight: 700;
										color: @color1;
									}
								}

								&:hover {
									a {
										transform: none;
										background: none;
										color: #000;
									}
								}
							}
						}
					}
				}

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		&.background-dark {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);

			.header-flexbox {
				border: none;
			}
		}
	}

	section.section-welcome {
		.owl-carousel {
			.item {
				h1, h2 {
					font-size: 50px;
					letter-spacing: 13px;
					line-height: 70px;
				}
			}
		}
	}

	.welcome-owl-nav {
		.list-social {
			li {
				a {
					p {
						display: none;
					}
				}
			}
		}
	}

	section.section-about {
		margin-top: 100px;

		.about-image {
			display: none;
		}

		.about-flexbox {
			.flexbox-description {
				width: 100%;
				text-align: center;

				span {
					&:after {
						margin: 15px auto 40px auto;
					}
				}

				p {
					width: 100%;
				}
			}
		}
	}

	section.section-partners {
		.partners-flexbox {
			.flexbox-heading {
				display: none;
			}

			.flexbox-owl {
				padding: 70px 0;
				width: 100%;
			}
		}
	}

	section.section-offer {
		.offer-flexbox {
			flex-direction: column;

			.flexbox-service-big {
				background: none !important;
				width: 100%;
				padding-top: 0;

				.service-text {
					position: relative;
					bottom: auto;
					left: auto;
					width: 100%;
					height: 100%;
					padding: 0px;
					display: block;
					text-align: center;

					h2 {
						color: #434343;

						&:after {
							margin: 23px auto 0 auto;
							background: #434343;
						}
					}

					p {
						width: 100%;
						color: #777;
					}
				}
			}

			.flexbox-service-wrapper {
				width: 100%;
				margin-top: 60px;
			}
		}
	}

	section.section-schiedel {
		.schiedel-flexbox {
			.flexbox-description {
				width: 100%;
				text-align: center;

				span {
					&:after {
						margin: 15px auto 40px auto;
					}
				}

				p {
					width: 100%;
				}
			}

			.flexbox-image {
				display: none;
			}
		}
	}

	section.section-news {
		.section-heading {
			text-align: center;

			h2 {
				a {
					padding: 0;

					&:after {
						content: none;
					}
				}
			}

			span {
				&:after {
					margin: 15px auto 0 auto;
				}
			}
		}

		.flexbox-news {
			.news-service {
				width: 47%;

				&:last-child {
					display: none;
				}
			}
		}
	}

	section.o-nas-section-about-content {
		.about-content-info {
			padding: 80px 0;
	
			.content-flexbox {
				width: 100%;
	
				.flexbox-body {
					width: 100%;
					text-align: center;
					transform: translateY(0);
					 p {
						font-size: 13px;
					 }
				}
	
				.flexbox-sidebar {
					display: none;
				}
			}
		}

		.about-certificates {
			.certificates-inner {
				.section-heading {
					display: flex;
					justify-content: center;
					text-align: center;
					margin-top: 70px;

					h2 {
						font-size: 30px;
						line-height: 45px;
						letter-spacing: 6px;
					}
				}

				.inner-description {
					margin-top: 50px;
					text-align: center;

					p {
						width: 100%;
					}
				}

				.gallery-flexbox {
					padding-right: 0;
				}
			}
		}

		.about-team {
			padding: 70px 0;

			.team-flexbox-wrapper {
				flex-direction: column;

				.team-description {
					width: 100%;
					text-align: center;

					span {
						&:after {
							margin: 15px auto 40px auto;
						}
					}
				}

				.team-flexbox {
					margin-top: 50px;
					width: 100%;
				}
			}
		}

		.about-gallery {
			.section-heading {
				text-align: center;

				span {
					&:after {
						margin: 15px auto 40px auto;
					}
				}
			}
		}

		.about-partners {
			.section-heading {
				text-align: center;

				span {
					&:after {
						margin: 15px auto 40px auto;
					}
				}
			}
		}
	}

	section.oferta-section-content {
		padding-bottom: 40px;

		.section-heading {
			text-align: center;

			span {
				&:after {
					margin: 15px auto 0 auto;
				}
			}
		}

		.content-flexbox {
			width: 100%;

			.flexbox-body {
				width: 100%;
				text-align: center;
				transform: translateY(0);
			}

			.flexbox-sidebar {
				display: none;
			}
		}

		&.white {
			.img-absolute {
				display: none;
			}
		}
	}

	section.oferta-section-cta {
		padding-top: 0;

		.about-image {
			left: auto;
			bottom: auto;
			position: relative;
			max-width: 300px;
			width: 100%;
			margin: 0 auto;
			transform: translateY(-70px);
		}

		.about-flexbox {
			.flexbox-description {
				width: 100%;
				text-align: center;

				span {
					&:after {
						margin: 15px auto 40px auto;
					}
				}

				p {
					width: 100%;
				}
			}
		}

		&.white {
			background: #fff;

			h2, span, p {
				color: #434343 !important;
			}

			span:after {
				background: rgba(0, 0, 0, 0.3) !important;
			}
		}
	}

	section.realizacje-section-content {
		.section-heading {
			text-align: center;

			span {
				&:after {
					margin: 15px auto 0 auto;
				}
			}

			p {
				width: 100%;
			}
		}

		.content-category {
			flex-direction: column;
			align-items: center;
			text-align: center;

			p {
				margin-right: 0;
			}

			ul {
				margin: 30px;
				justify-content: center;
			}
		}

		.content-flexbox {
			flex-direction: column;

			.flexbox-body {
				width: 100%;
			}

			.flexbox-sidebar {
				width: 100%;

				.sidebar-heading {
					text-align: center;
					margin-right: 0;
				}

				.description {
					text-align: center;
				}

				.contact {
					text-align: center;

					a {
						display: block;
					}
				}
			}
		}
	}

	section.wpis-section-content {
		.section-heading {
			text-align: center;

			h1 {
				a {
					&:before {
						left: 50%;
						transform: translateX(-50%);
					}

					&:after {
						left: 50%;
						transform: translateX(-50%);
					}
				}
			}

			span {
				&:after {
					margin: 15px auto 0 auto;
				}
			}
		}

		.content-flexbox {
			width: 100%;
		}
	}

	section.static-page-section-content {
		.content-inner {
			width: 100%;
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-wrap: wrap;

			.flexbox-service {
				width: calc(100% / 3);

				.list-social {
					justify-content: flex-start;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	.section-header-wrapper {
		min-height: calc(~'100vh - 70px');

		.header-background {
			background: url(../img/backgrounds/fotolia_279557805.jpg) center;
			background-size: cover;

			.owl-carousel-welcome {
				display: none;
			}
		}
	}

	section.section-welcome {
		.welcome-content {
			.content-inner {
				display: block;
				text-align: center;

				h1, h2 {
					color: #fff;
					font-size: 40px;
					text-transform: uppercase;
					letter-spacing: 10px;
					font-weight: 700;
					line-height: 60px;
				}

				p {
					width: 100%;
					text-transform: uppercase;
					color: #fff;
					font-size: 16px;
					font-weight: 300;
					letter-spacing: 4px;

					&:before {
						content: '';
						display: block;
						width: 80px;
						height: 1px;
						margin: 30px auto;
						background: rgba(255, 255, 255, 0.8);
					}
				}
			}
		}

		.owl-carousel {
			display: none;
		}
	}

	.welcome-owl-nav {
		display: none;
	}

	.welcome-cooperate {
		margin-top: 70px;

		.cooperate-inner {
			justify-content: space-between;

			.inner-service {
				height: 200px;
				width: 47%;
				margin-right: 0px;
			}
		}
	}

	section.section-about {
		margin-top: 70px;
		padding: 70px 0;
	}

	section.section-offer {
		.offer-flexbox {
			.flexbox-service-wrapper {
				.service-inner {
					width: calc(100% / 2);
					padding-top: calc(100% / 2);

					.inner-text {
						left: 15px;
						bottom: 12px;
					}
				}
			}
		}
	}

	section.wpis-section-content {
		.content-flexbox {
			flex-direction: column;

			.flexbox-sidebar {
				width: 100%;

				img {
					width: 100%;
				}

				.sidebar-gallery {
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;

					a {
						margin-top: 5%;
						width: 30%;
						padding-top: 30%;
						overflow: hidden;
						position: relative;

						img {
							position: absolute;
							top: 0;
							left: 0;
						} 
					}

					.box-hidden {
						margin: 0;
						padding: 0;
						width: 30%;
						opacity: 0;
						visibility: hidden;
					}
				}
			}

			.flexbox-body {
				margin-top: 50px;
				width: 100%;
				text-align: center;
			}
		}
	}

	section.kontakt-section-content {
		.section-heading {
			text-align: center;

			span {
				&:after {
					margin: 15px auto 0 auto;

				}
			}
		}

		.content-contact-details {
			.details-background-color{
				display: none;
			}

			.details-info {
				padding: 0;
				flex-direction: column-reverse;

				.info-iframe {
					margin-top: 50px;
					width: 100%;
				}

				.info-description {
					width: 100%;
					text-align: center;

					h3 {
						color: #434343;
					}

					p {
						color: #777;
					}

					.details-list {
						list-style: none;

						.list-item {
							padding: 8px 0;

							span {
								color: #434343;
							}

							a {
								color: @color1;
								font-weight: 700;
							}
						}
					}
				}

			}
		}
	}

	section.static-page-section-content {
		.section-heading {
			text-align: center;

			h1 {
				&:after {
					margin: 15px auto 0 auto;
				}
			}
		}

		.content-inner {
			h2, h3 {
				text-align: center;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	.pagination-nav {
		margin-top: 60px;
	}

	section.section-welcome {
		.welcome-content {
			.content-inner {
				h1, h2 {
					font-size: 30px;
					letter-spacing: 6px;
					line-height: 45px;
				}

				p {
					font-size: 15px;
					letter-spacing: 2px;
				}
			}
		}
	}

	section.section-about {
		.about-flexbox {
			.flexbox-description {
				h2 {
					font-size: 30px;
					letter-spacing: 6px;
					line-height: 45px;
				}

				span {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}
	}

	section.section-schiedel {
		padding-top: 70px;

		.schiedel-flexbox {
			.flexbox-description {
				h2 {
					font-size: 30px;
					letter-spacing: 6px;
					line-height: 45px;
				}

				span {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}
	}

	section.section-news {
		margin-top: 70px;
		padding-top: 70px;
		padding-bottom: 70px;

		.section-heading {
			h1, h2 {
				font-size: 30px;
				letter-spacing: 6px;
				line-height: 45px;
			}

			span {
				letter-spacing: 2px;
				font-size: 15px;
			}
		}

		.flexbox-news {
			flex-direction: column;

			.news-service {
				height: 350px;
				width: 100%;
			}
		}
	}

	section.o-nas-section-about-content {
		.about-content-info {
			.section-heading {
				text-align: center;
				h1 {
					font-size: 26px;
    				letter-spacing: 6px;
    				line-height: 45px;
				}
				h2 {
					margin-top: 10px;
					font-size: 17px;
				}
			}
	
			.content-flexbox {
				width: 100%;
	
				.flexbox-body {
					width: 100%;
					text-align: center;
					transform: translateY(0);
				}
	
				.flexbox-sidebar {
					display: none;
				}
			}

		}

		.about-certificates {
			.certificates-inner {
				.gallery-flexbox {
					a {
						margin-top: 3%;
						width: 31%;
						padding-top: 31%;
					}

					.box-hidden {
						width: 31%;
					}
				}
			}
		}

		.about-team {
			.team-flexbox-wrapper {
				.team-description {
					h2 {
						font-size: 30px;
						letter-spacing: 6px;
						line-height: 45px;
					}

					span {
						letter-spacing: 2px;
						font-size: 15px;
					}
				}

				.team-flexbox {
					margin-top: 0;
					flex-direction: column;
					align-items: center;

					.flexbox-service {
						margin-top: 30px;
						width: 100%;
						max-width: 200px;
						text-align: center;
					}
				}
			}
		}

		.about-gallery {
			padding: 60px 0 70px 0;

			.section-heading {
				h2 {
					font-size: 30px;
					letter-spacing: 6px;
					line-height: 45px;
				}

				span {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}

			.gallery-flexbox {
				a {
					margin-top: 3%;
					width: 31%;
					padding-top: 31%;
				}

				.box-hidden {
					width: 31%;
				}
			}
		}

		.about-partners {
			padding: 60px 0 70px 0;

			.section-heading {
				h2 {
					font-size: 30px;
					letter-spacing: 6px;
					line-height: 45px;
				}

				h3 {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}
	}

	section.oferta-section-content {
		padding-top: 70px;

		.section-heading {
			h1 {
				font-size: 30px;
				letter-spacing: 6px;
				line-height: 45px;
			}

			span {
				letter-spacing: 2px;
				font-size: 15px;
			}
		}

		.content-flexbox {
			margin-top: 50px;

			.flexbox-body {
				h2 {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}
	}

	section.oferta-section-cta {
		.about-flexbox {
			.flexbox-description {
				h2 {
					font-size: 30px;
					letter-spacing: 6px;
					line-height: 45px;
				}

				span {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}
	}

	section.oferta-section-gallery {
		.gallery-flexbox {
			a {
				margin-top: 3%;
				width: 31%;
				padding-top: 31%;
			}

			.box-hidden {
				width: 31%;
			}
		}
	}

	section.realizacje-section-content {
		padding-top: 70px;
		padding-bottom: 60px;

		.section-heading {
			h1 {
				font-size: 30px;
				letter-spacing: 6px;
				line-height: 45px;
			}

			span {
				letter-spacing: 2px;
				font-size: 15px;
			}
		}

		.content-category {
			p {
				letter-spacing: 2px;
				font-size: 15px;
			}

			ul {
				margin-top: 20px;
			}
		}

		.content-flexbox {
			.flexbox-body {
				.flexbox-news {
					margin-top: 30px;

					.news-service {
						margin-top: 6% !important;
						width: 100%;
					}
				}
			}

			.flexbox-sidebar {
				margin-top: 40px;

				.sidebar-heading {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}
	}

	section.wpis-section-content {
		padding-top: 70px;
		padding-bottom: 60px;

		.section-heading {
			h1 {
				font-size: 30px;
				letter-spacing: 6px;
				line-height: 45px;
			}

			span {
				letter-spacing: 2px;
				font-size: 15px;
			}
		}

		.content-flexbox {
			margin-top: 60px;

			.flexbox-sidebar {
				.sidebar-gallery {
					a {
						margin-top: 2%;
						width: 32%;
						padding-top: 32%;
					}

					.box-hidden {
						width: 32%;
					}
				}
			}

			.flexbox-body {
				h2, h3 {
					letter-spacing: 2px;
					font-size: 15px;
				}
			}
		}

		&.realizacja {
			padding-bottom: 60px;
		}
	}

	section.kontakt-section-content {
		.section-heading {
			padding-top: 70px;

			h1 {
				font-size: 30px;
				letter-spacing: 6px;
				line-height: 45px;
			}

			span {
				letter-spacing: 2px;
				font-size: 15px;
			}
		}

		.content-contact-details {
			margin-top: 50px;
		}
	}

	section.static-page-section-content {
		padding: 70px 0 60px 0;

		.section-heading {
			h1 {
				font-size: 30px;
				letter-spacing: 6px;
				line-height: 45px;
			}
		}

		.content-inner {
			h2, h3 {
				letter-spacing: 2px;
				font-size: 15px;
			}
		}
	}

	section.section-footer {
		.footer-logo {
			img {
				max-width: 260px;
				width: 100%;
			}
		}

		.footer-flexbox {
			flex-direction: column;
			align-items: center;
			text-align: center;

			.flexbox-service {
				margin-top: 50px;
				width: 100%;

				.list-social {
					justify-content: center;
				}
			}
		}

		.footer-credits {
			text-align: center;
			justify-content: center;
		}

		.alert {
			text-align: center;
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {
	.welcome-cooperate {
		.cooperate-inner {
			flex-direction: column;

			.inner-service {
				width: 100%;
				height: 130px;

				&:nth-child(2) {
					margin-top: 30px;
				}
			}
		}
	}

	section.section-offer {
		.offer-flexbox {
			.flexbox-service-wrapper {
				.service-inner {
					width: 100%;
					padding-top: 50%;

					.inner-text {
						left: 0;
						bottom: 12px;
						text-align: center;
						width: 100%;
					}

					&:not(:first-child) {
						margin-top: 30px;
					}
				}
			}
		}
	}
}