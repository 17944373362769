@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(https://fonts.gstatic.com/s/poppins/v8/pxiByp8kv8JHgFVrLFj_Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Poppins Light'), local('Poppins-Light'), url(https://fonts.gstatic.com/s/poppins/v8/pxiByp8kv8JHgFVrLDz8Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v8/pxiEyp8kv8JHgFVrJJnedw.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(https://fonts.gstatic.com/s/poppins/v8/pxiByp8kv8JHgFVrLEj6Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Poppins Bold'), local('Poppins-Bold'), url(https://fonts.gstatic.com/s/poppins/v8/pxiByp8kv8JHgFVrLCz7Z1JlEA.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url(https://fonts.gstatic.com/s/poppins/v8/pxiByp8kv8JHgFVrLDD4Z1JlEA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
body .btn {
  padding: 10px 30px;
  border-radius: 0;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.3s;
}
body .btn.btn-default {
  border: 1px solid #fff;
  background: none;
  color: #fff;
}
body .btn.btn-default:hover {
  background: #fff;
  color: #666;
}
.system-alerts {
  width: 100%;
  text-align: center;
}
.system-alerts .alert {
  border-radius: 0;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  opacity: 0.9;
  transition: all 0.4s;
}
.system-alerts .alert .alert-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.system-alerts .alert .alert-inner .img-hidden {
  visibility: hidden;
  opacity: 0;
  height: 18px;
}
.system-alerts .alert .alert-inner p {
  color: #fff;
  padding: 0 50px;
}
.system-alerts .alert .alert-inner button {
  color: #fff;
  opacity: 1;
  font-weight: 300;
  float: none;
}
.system-alerts .alert .alert-inner button img {
  height: 18px;
}
.system-alerts .alert.alert-success {
  background: #76ad21;
}
.system-alerts .alert.alert-info {
  background: #218ead;
}
.system-alerts .alert.alert-warning {
  background: #c52b20;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.section-header-wrapper {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section-header-wrapper .header-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.section-header-wrapper .header-background .owl-carousel-welcome {
  height: 100%;
}
.section-header-wrapper .header-background .owl-carousel-welcome .owl-stage,
.section-header-wrapper .header-background .owl-carousel-welcome .owl-stage-outer,
.section-header-wrapper .header-background .owl-carousel-welcome .owl-item {
  height: 100%;
}
.section-header-wrapper .header-background .owl-carousel-welcome .item {
  width: 100%;
  height: 100%;
}
@keyframes kenburns {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}
.section-header-wrapper .container {
  position: relative;
}
.header-slogan {
  width: 100%;
  position: absolute;
  z-index: 111;
  right: 0;
  bottom: 180px;
}
.header-slogan .slogan-inner {
  display: flex;
  justify-content: flex-end;
}
.header-slogan .slogan-inner .inner-service {
  transform: rotate(90deg) translateY(100%);
  transform-origin: right bottom;
  position: relative;
}
.header-slogan .slogan-inner .inner-service span {
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.header-slogan .slogan-inner .inner-service p {
  margin-top: 10px;
  font-weight: 700;
  color: #fff;
  font-size: 21px;
  letter-spacing: 2px;
}
.header-slogan .slogan-inner .inner-service:after {
  content: '';
  height: 2px;
  width: 40px;
  background: #fff;
  bottom: -20px;
  left: -30px;
  display: block;
  position: absolute;
}
.header-slogan .slogan-inner .inner-service:before {
  content: '';
  width: 2px;
  height: 40px;
  background: #fff;
  bottom: -20px;
  left: -30px;
  display: block;
  position: absolute;
}
.header-slogan.dark .slogan-inner .inner-service span {
  color: #434343;
}
.header-slogan.dark .slogan-inner .inner-service p {
  color: #434343;
}
.header-slogan.dark .slogan-inner .inner-service:after {
  background: #434343;
}
.header-slogan.dark .slogan-inner .inner-service:before {
  background: #434343;
}
.pagination-nav {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagination-nav li a {
  display: block;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.4s;
}
.pagination-nav li a:hover {
  color: #f2a900;
}
.pagination-nav li.active a {
  font-weight: 700;
  color: #f2a900;
  font-size: 28px;
}
nav.section-header {
  position: relative;
  z-index: 222;
}
nav.section-header .header-flexbox {
  padding: 25px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}
nav.section-header .header-flexbox .flexbox-logo {
  position: relative;
}
nav.section-header .header-flexbox .flexbox-logo .logo-white {
  height: 50px;
  opacity: 0;
}
nav.section-header .header-flexbox .flexbox-logo .logo-absolute {
  top: 0;
  left: 0;
  opacity: 1;
  position: absolute;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  margin-right: 40px;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #333;
  display: block;
  font-weight: 300;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
  font-weight: 700;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
  transform: translateY(-3px);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a.active {
  transform: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu {
  margin-top: 62px;
  padding: 10px 10px;
  min-width: 12rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  border: none;
  position: relative;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu .dropdown-item {
  padding: 7px;
  font-size: 14px;
  transition: all 0.3s;
  transform: none !important;
  color: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu .dropdown-item.highlight {
  background: #f2a900;
  margin-bottom: 4px;
  font-weight: 500;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu .dropdown-item.highlight:hover {
  background: #bf8500;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .dropdown .dropdown-menu:before {
  content: '';
  display: block;
  position: absolute;
  left: 22px;
  top: -5px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 4px 5px 4px;
  border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
}
@media (min-width: 992px) {
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .animate {
    animation-duration: 0.5s;
    -webkit-animation-duration: 0.5s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}
@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
}
@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
  0% {
    -webkit-transform: translateY(-50px);
    -webkit-opacity: 0;
  }
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li .slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.li-mobile {
  display: none;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight a {
  padding: 10px 20px;
  border: 1px solid #333;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight a.active {
  background: #333;
  color: #fff;
  font-weight: 400;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight:hover a {
  transform: none;
  background: #333;
  color: #fff;
}
nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
  cursor: pointer;
}
nav.section-header .header-flexbox .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #434343;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-flexbox .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-flexbox .nav-button:hover .button-bar,
nav.section-header .header-flexbox .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header.background-dark .header-flexbox {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
nav.section-header.background-dark .header-flexbox .nav-button .button-bar {
  background: #fff;
}
nav.section-header.background-dark .flexbox-logo .logo-absolute {
  opacity: 0;
}
nav.section-header.background-dark .flexbox-logo .logo-white {
  opacity: 1;
}
nav.section-header.background-dark .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #fff;
}
nav.section-header.background-dark .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight a {
  border: 1px solid #fff;
}
nav.section-header.background-dark .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight a.active {
  background: #fff;
}
nav.section-header.background-dark .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight:hover a {
  background: #fff;
  color: #666;
}
section.section-welcome {
  position: relative;
  z-index: 111;
}
section.section-welcome .welcome-content {
  width: 70%;
  padding: 100px 0;
}
section.section-welcome .welcome-content .content-inner {
  display: none;
}
section.section-welcome .owl-carousel .item h1,
section.section-welcome .owl-carousel .item h2 {
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-weight: 700;
  line-height: 80px;
}
section.section-welcome .owl-carousel .item p {
  width: 80%;
  margin-top: 30px;
  text-transform: uppercase;
  color: #fff;
  display: flex;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 5px;
}
section.section-welcome .owl-carousel .item p:before {
  content: '';
  margin-right: 20px;
  display: block;
  min-width: 80px;
  height: 1px;
  transform: translateY(10px);
  background: rgba(255, 255, 255, 0.8);
}
section.section-welcome .owl-carousel .owl-controls {
  display: none;
}
.welcome-bottom-wrapper {
  position: relative;
  z-index: 111;
}
.welcome-owl-nav {
  position: absolute;
  z-index: 112;
  top: 135px;
  left: 0;
  width: 100%;
}
.welcome-owl-nav .owl-custom-nav {
  display: none;
  align-items: center;
}
.welcome-owl-nav .owl-custom-nav .owl-nav-link {
  height: 30px;
  width: 30px;
  display: block;
  border: 0.5px solid #000;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
}
.welcome-owl-nav .owl-custom-nav .owl-nav-link.active:after {
  content: '';
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background: #f2a900;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.4s;
}
.welcome-owl-nav .owl-custom-nav .owl-nav-link:last-child {
  margin-right: 0;
}
.welcome-owl-nav .list-social {
  display: flex;
  align-items: center;
}
.welcome-owl-nav .list-social li {
  margin-right: 30px;
}
.welcome-owl-nav .list-social li a {
  display: flex;
  align-items: center;
}
.welcome-owl-nav .list-social li a img {
  height: 27px;
  margin-right: 10px;
  filter: grayscale(50%);
  transition: filter 0.4s;
}
.welcome-owl-nav .list-social li a p {
  font-size: 13px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #777;
  font-weight: 300;
}
.welcome-owl-nav .list-social li a:hover img {
  filter: none;
}
.welcome-owl-nav .list-social li:last-child {
  margin-right: 0;
}
.welcome-cooperate {
  position: relative;
  width: 100%;
  margin-top: -100px;
}
.welcome-cooperate .cooperate-inner {
  display: flex;
  justify-content: flex-end;
}
.welcome-cooperate .cooperate-inner .inner-service {
  height: 200px;
  width: 200px;
  display: block;
  background: #333;
  margin-right: 50px;
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
  transition: all 0.3s;
}
.welcome-cooperate .cooperate-inner .inner-service:last-child {
  margin-right: 0;
}
.welcome-cooperate .cooperate-inner .inner-service:hover {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.22);
}
section.section-about {
  margin-top: 150px;
  padding: 80px 0;
  background: #516b76;
  background: #726b5c;
  position: relative;
}
section.section-about .container {
  position: relative;
}
section.section-about .about-image {
  position: absolute;
  left: 15px;
  bottom: 0;
}
section.section-about .about-flexbox {
  display: flex;
  justify-content: flex-end;
}
section.section-about .about-flexbox .flexbox-description {
  width: 60%;
}
section.section-about .about-flexbox .flexbox-description h2 {
  color: #fff;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
}
section.section-about .about-flexbox .flexbox-description h3 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.section-about .about-flexbox .flexbox-description h3:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
}
section.section-about .about-flexbox .flexbox-description p {
  width: 100%;
  margin-top: 15px;
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.section-about .about-flexbox .flexbox-description .btn {
  margin-top: 20px;
}
section.section-partners .partners-flexbox {
  display: flex;
  justify-content: space-between;
}
section.section-partners .partners-flexbox .flexbox-heading {
  width: 23%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2a900;
}
section.section-partners .partners-flexbox .flexbox-heading p {
  padding: 10px 30px;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  text-transform: uppercase;
}
section.section-partners .partners-flexbox .flexbox-owl {
  padding: 20px 0;
  width: 70%;
}
section.section-partners .partners-flexbox .flexbox-owl .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
section.section-partners .partners-flexbox .flexbox-owl .owl-carousel .item img {
  opacity: 0.5;
  transition: all 0.3s;
}
section.section-partners .partners-flexbox .flexbox-owl .owl-carousel .item img:hover {
  opacity: 1;
}
section.section-partners .partners-flexbox .flexbox-owl .owl-carousel .owl-controls {
  display: none;
}
section.section-offer .offer-flexbox {
  display: flex;
}
section.section-offer .offer-flexbox .flexbox-service-big {
  width: 40%;
  padding-top: 40%;
  position: relative;
}
section.section-offer .offer-flexbox .flexbox-service-big .service-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
section.section-offer .offer-flexbox .flexbox-service-big .service-text h2 {
  letter-spacing: 5px;
  font-size: 32px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
section.section-offer .offer-flexbox .flexbox-service-big .service-text h2:after {
  margin-top: 23px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: #fff;
}
section.section-offer .offer-flexbox .flexbox-service-big .service-text p {
  width: 90%;
  margin-top: 20px;
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner {
  width: calc(100% / 3);
  padding-top: calc(100% / 3);
  position: relative;
  filter: grayscale(30%);
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  transition: all 0.3s;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner .inner-text {
  position: absolute;
  bottom: 15px;
  left: 25px;
  letter-spacing: 5px;
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  text-transform: uppercase;
  z-index: 111;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact:after {
  content: none;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact p {
  letter-spacing: 5px;
  font-size: 17px;
  color: #333;
  font-weight: 300;
  text-transform: uppercase;
  transition: all 0.3s;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact p:before {
  margin: 0 auto;
  margin-bottom: 23px;
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  background: #333;
  transition: all 0.3s;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact:hover p {
  transform: translateY(-12px);
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact:hover p:before {
  width: 0;
}
section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner:hover:after {
  height: 100%;
}
section.section-schiedel {
  padding-top: 120px;
}
section.section-schiedel .schiedel-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.section-schiedel .schiedel-flexbox .flexbox-description {
  width: 60%;
}
section.section-schiedel .schiedel-flexbox .flexbox-description h2 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
}
section.section-schiedel .schiedel-flexbox .flexbox-description span {
  margin-top: 10px;
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.section-schiedel .schiedel-flexbox .flexbox-description span:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.section-schiedel .schiedel-flexbox .flexbox-description p {
  width: 90%;
  margin-top: 15px;
  font-weight: 300;
  color: #777;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.section-schiedel .schiedel-flexbox .flexbox-description .btn {
  margin-top: 20px;
}
section.section-schiedel .schiedel-flexbox .flexbox-image {
  width: 35%;
}
section.section-schiedel .schiedel-flexbox .flexbox-image img {
  width: 100%;
}
section.section-news {
  background: #232323;
  background: url(../img/backgrounds/news-background.jpg);
  background-size: cover;
  background-position: center bottom;
  margin-top: -150px;
  padding-top: 200px;
  padding-bottom: 100px;
}
section.section-news.subpage {
  margin-top: 0;
  padding-top: 100px;
  padding-bottom: 40px;
}
section.section-news .section-heading h1,
section.section-news .section-heading h2 {
  color: #fff;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
  position: relative;
}
section.section-news .section-heading h1 a,
section.section-news .section-heading h2 a {
  color: #fff;
  padding-right: 100px;
  display: block;
}
section.section-news .section-heading h1 a:after,
section.section-news .section-heading h2 a:after {
  content: 'Zobacz całą bazę wiedzy';
  position: absolute;
  font-size: 13px;
  letter-spacing: 2px;
  opacity: 0;
  transition: all 0.4s;
  bottom: -35px;
}
section.section-news .section-heading h1 a:hover:after,
section.section-news .section-heading h2 a:hover:after {
  opacity: 0.5;
  bottom: -9px;
}
section.section-news .section-heading h3 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.section-news .section-heading h3:after {
  margin-top: 15px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
}
section.section-news .flexbox-news {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.section-news .flexbox-news .news-service {
  margin-top: 60px;
  height: 420px;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s;
}
section.section-news .flexbox-news .news-service .service-inner {
  width: 90%;
  padding: 15px;
  padding-left: 0;
  min-height: 80px;
  background: #232323;
}
section.section-news .flexbox-news .news-service .service-inner h3 {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
}
section.section-news .flexbox-news .news-service:hover {
  opacity: 0.7;
}
section.o-nas-section-about-content .about-content-info {
  position: relative;
  padding: 100px 0 130px 0;
  background: #726b5c;
}
section.o-nas-section-about-content .about-content-info .section-heading h1 {
  color: #fff;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 600;
  line-height: 50px;
}
section.o-nas-section-about-content .about-content-info .section-heading h2 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.o-nas-section-about-content .about-content-info .section-heading h2:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
}
section.o-nas-section-about-content .about-content-info .content-flexbox {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body {
  width: 55%;
  transform: translateY(-5px);
}
section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body p {
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
  margin-top: 15px;
}
section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body > * {
  margin-top: 15px;
}
section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body > *:first-child {
  margin-top: 0;
}
section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-sidebar {
  width: 40%;
}
section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-sidebar img {
  width: 100%;
}
section.o-nas-section-about-content .about-certificates .container {
  position: relative;
}
section.o-nas-section-about-content .about-certificates .certificates-inner {
  position: relative;
  width: 100%;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .section-heading {
  margin-top: -30px;
  background: #f2a900;
  padding: 40px;
  display: inline-block;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .section-heading h2 {
  font-size: 40px;
  line-height: 50px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 7px;
  text-transform: uppercase;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-description {
  margin-top: 50px;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-description p {
  width: 60%;
  margin-top: 15px;
  font-weight: 300;
  color: #777;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-description p:first-child {
  margin-top: 0;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .gallery-flexbox {
  margin-top: 35px;
  padding-right: 30%;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .gallery-flexbox a {
  margin-top: 2%;
  width: 23.5%;
  padding-top: 23.5%;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
}
section.o-nas-section-about-content .about-certificates .certificates-inner .gallery-flexbox .box-hidden {
  width: 23.5%;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan {
  width: 38%;
  height: 10%;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 70%;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan .slogan-inner {
  display: flex;
  justify-content: flex-end;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan .slogan-inner .inner-service {
  transform: rotate(90deg) translateY(100%);
  transform-origin: right bottom;
  position: relative;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan .slogan-inner .inner-service span {
  font-weight: 300;
  color: #333;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan .slogan-inner .inner-service p {
  margin-top: 10px;
  font-weight: 700;
  color: #333;
  font-size: 21px;
  letter-spacing: 2px;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan .slogan-inner .inner-service:after {
  content: '';
  height: 2px;
  width: 40px;
  background: #232323;
  bottom: -20px;
  left: -30px;
  display: block;
  position: absolute;
}
section.o-nas-section-about-content .about-certificates .certificates-inner .inner-certificates-slogan .slogan-inner .inner-service:before {
  content: '';
  width: 2px;
  height: 40px;
  background: #232323;
  bottom: -20px;
  left: -30px;
  display: block;
  position: absolute;
}
section.o-nas-section-about-content .about-team {
  padding: 100px 0;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper {
  display: flex;
  justify-content: space-between;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description {
  width: 35%;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description h2 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 600;
  line-height: 50px;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description h3 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description h3:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description p {
  margin-top: 15px;
  font-weight: 300;
  color: #777;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox .flexbox-service {
  width: 31%;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox .flexbox-service .service-image {
  width: 100%;
  padding-top: 100%;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox .flexbox-service .service-description h3 {
  margin: 10px 0 5px 0;
  color: #434343;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2;
  font-weight: 600;
}
section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox .flexbox-service .service-description p {
  font-size: 13px;
  color: #777;
  font-weight: 500;
}
section.o-nas-section-about-content .about-gallery {
  background-color: #242424;
  padding: 80px 0;
}
section.o-nas-section-about-content .about-gallery .section-heading h2 {
  color: #fff;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 600;
  line-height: 50px;
}
section.o-nas-section-about-content .about-gallery .section-heading h3 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.o-nas-section-about-content .about-gallery .section-heading h3:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
}
section.o-nas-section-about-content .about-partners {
  padding: 80px 0;
}
section.o-nas-section-about-content .about-partners .section-heading h2 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 600;
  line-height: 50px;
}
section.o-nas-section-about-content .about-partners .section-heading h3 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.o-nas-section-about-content .about-partners .section-heading h3:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.o-nas-section-about-content .about-partners .owl-carousel .owl-stage {
  display: flex;
  align-items: center;
}
section.o-nas-section-about-content .about-partners .owl-carousel .item img {
  opacity: 0.5;
  transition: all 0.3s;
}
section.o-nas-section-about-content .about-partners .owl-carousel .item img:hover {
  opacity: 1;
}
section.o-nas-section-about-content .about-partners .owl-carousel .owl-controls {
  display: none;
}
section.oferta-section-content {
  padding-top: 100px;
  padding-bottom: 80px;
}
section.oferta-section-content .section-heading {
  position: relative;
}
section.oferta-section-content .section-heading h1 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
}
section.oferta-section-content .section-heading h2 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.oferta-section-content .section-heading h2:after {
  margin-top: 15px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.oferta-section-content .section-heading p {
  width: 70%;
  margin-top: 15px;
  font-weight: 300;
  color: #777;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.oferta-section-content .content-flexbox {
  position: relative;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
section.oferta-section-content .content-flexbox .flexbox-body {
  width: 55%;
  transform: translateY(-5px);
}
section.oferta-section-content .content-flexbox .flexbox-body h2,
section.oferta-section-content .content-flexbox .flexbox-body h3 {
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
}
section.oferta-section-content .content-flexbox .flexbox-body > * {
  margin-top: 15px;
}
section.oferta-section-content .content-flexbox .flexbox-body > *:first-child {
  margin-top: 0;
}
section.oferta-section-content .content-flexbox .flexbox-body p,
section.oferta-section-content .content-flexbox .flexbox-body ol,
section.oferta-section-content .content-flexbox .flexbox-body ul {
  font-weight: 300;
  color: #434343;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.oferta-section-content .content-flexbox .flexbox-body ol,
section.oferta-section-content .content-flexbox .flexbox-body ul {
  padding-left: 17px;
}
section.oferta-section-content .content-flexbox .flexbox-sidebar {
  width: 40%;
}
section.oferta-section-content .content-flexbox .flexbox-sidebar img {
  width: 100%;
}
section.oferta-section-content.white {
  position: relative;
}
section.oferta-section-content.white .img-absolute {
  position: absolute;
  width: 70%;
  top: 200px;
  right: 80px;
  opacity: 0.15;
}
section.oferta-section-content.white * {
  color: #fff !important;
}
section.oferta-section-content.white h2:after {
  background: rgba(255, 255, 255, 0.8) !important;
}
section.oferta-section-cta {
  margin-top: 100px;
  padding: 80px 0;
  background: #726b5c;
  position: relative;
}
section.oferta-section-cta .container {
  position: relative;
}
section.oferta-section-cta .about-image {
  position: absolute;
  left: 15px;
  bottom: 0;
  width: 390px;
}
section.oferta-section-cta .about-image img {
  width: 100%;
}
section.oferta-section-cta .about-flexbox {
  display: flex;
  justify-content: flex-end;
}
section.oferta-section-cta .about-flexbox .flexbox-description {
  width: 60%;
}
section.oferta-section-cta .about-flexbox .flexbox-description h2 {
  color: #fff;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
}
section.oferta-section-cta .about-flexbox .flexbox-description h3 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.oferta-section-cta .about-flexbox .flexbox-description h3:after {
  margin-top: 15px;
  margin-bottom: 40px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
}
section.oferta-section-cta .about-flexbox .flexbox-description p {
  width: 70%;
  margin-top: 15px;
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.oferta-section-cta .about-flexbox .flexbox-description ol,
section.oferta-section-cta .about-flexbox .flexbox-description ul {
  padding-left: 17px;
}
section.oferta-section-cta .about-flexbox .flexbox-description ol li,
section.oferta-section-cta .about-flexbox .flexbox-description ul li {
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.oferta-section-cta .about-flexbox .flexbox-description .btn {
  margin-top: 30px;
}
section.oferta-section-cta.white {
  background: #fff;
}
section.oferta-section-cta.white h2,
section.oferta-section-cta.white span,
section.oferta-section-cta.white p,
section.oferta-section-cta.white h3 {
  color: #434343 !important;
}
section.oferta-section-cta.white h3:after {
  background: rgba(0, 0, 0, 0.3) !important;
}
section.oferta-section-gallery {
  padding-top: 60px;
  padding-bottom: 80px;
  background: url(../img/backgrounds/offer-background.jpg) center bottom;
  background-size: cover;
}
section.oferta-section-gallery.white {
  padding-top: 0;
  background: #fff;
}
.gallery-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.gallery-flexbox a {
  margin-top: 2.5%;
  width: 18%;
  padding-top: 18%;
  overflow: hidden;
  position: relative;
}
.gallery-flexbox a img {
  position: absolute;
  top: 0;
  left: 0;
}
.gallery-flexbox .box-hidden {
  margin: 0;
  padding: 0;
  width: 18%;
  opacity: 0;
  visibility: hidden;
}
section.realizacje-section-content {
  padding-top: 100px;
  padding-bottom: 80px;
}
section.realizacje-section-content .section-heading h1 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
}
section.realizacje-section-content .section-heading h2 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.realizacje-section-content .section-heading h2:after {
  margin-top: 15px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.realizacje-section-content .section-heading p {
  width: 70%;
  margin-top: 15px;
  font-weight: 300;
  color: #777;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.realizacje-section-content .content-category {
  margin-top: 40px;
  display: flex;
  align-items: center;
}
section.realizacje-section-content .content-category p {
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  margin-right: 30px;
}
section.realizacje-section-content .content-category ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
section.realizacje-section-content .content-category ul li {
  margin: 5px 15px 5px 0;
}
section.realizacje-section-content .content-category ul li a,
section.realizacje-section-content .content-category ul li button {
  padding: 7px 18px;
  border: 1px solid #333;
  color: #333;
  font-size: 12px;
  display: block;
  background: #fff;
  transition: all 0.4s;
  white-space: nowrap;
  cursor: pointer;
}
section.realizacje-section-content .content-category ul li a.active,
section.realizacje-section-content .content-category ul li button.active,
section.realizacje-section-content .content-category ul li a:hover,
section.realizacje-section-content .content-category ul li button:hover {
  background: #f2a900;
  border-color: #f2a900;
  color: #fff;
}
section.realizacje-section-content .content-category ul li:last-child {
  margin-right: 0;
}
section.realizacje-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
}
section.realizacje-section-content .content-flexbox .flexbox-body {
  width: 65%;
}
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service {
  margin-top: 6%;
  height: 300px;
  width: 47%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: all 0.3s;
}
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service:nth-child(1),
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service:nth-child(2) {
  margin-top: 0;
}
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service .service-inner {
  width: 90%;
  padding: 15px;
  padding-left: 0;
  min-height: 80px;
  background: #fff;
}
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service .service-inner h3 {
  color: #333;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 600;
}
section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service:hover {
  opacity: 0.7;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar {
  margin-top: 60px;
  width: 25%;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-heading {
  transform: translateY(-3px);
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  margin-right: 30px;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-cooperate {
  margin-top: 20px;
  position: relative;
  width: 100%;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-cooperate .cooperate-inner {
  display: flex;
  justify-content: space-between;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-cooperate .cooperate-inner .inner-service {
  padding-top: 20%;
  width: 45%;
  display: block;
  background: #333;
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
  transition: all 0.3s;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-cooperate .cooperate-inner .inner-service:hover {
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.22);
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .description {
  margin-top: 50px;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .description .text p {
  margin-top: 15px;
  font-weight: 300;
  color: #777;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .contact {
  margin-top: 50px;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .contact .sidebar-heading {
  margin-bottom: 15px;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .contact a {
  text-transform: uppercase;
  color: #777;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.4s;
}
section.realizacje-section-content .content-flexbox .flexbox-sidebar .contact a:hover {
  color: #f2a900;
}
section.realizacje-section-content .pagination-nav li a {
  color: #434343;
}
section.realizacje-section-content .pagination-nav li a:hover {
  color: #f2a900;
}
section.realizacje-section-content .pagination-nav li.active a {
  color: #f2a900;
}
section.wpis-section-content {
  background: #232323;
  padding-top: 100px;
  padding-bottom: 40px;
}
section.wpis-section-content .section-heading h1 {
  color: transparent;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
  position: relative;
  z-index: 111;
}
section.wpis-section-content .section-heading h1 a {
  color: inherit;
  display: block;
}
section.wpis-section-content .section-heading h1 a:before {
  content: 'TEMAT';
  transition: 0.4s;
  position: absolute;
  color: #fff;
  top: 0;
  cursor: pointer;
}
section.wpis-section-content .section-heading h1 a:after {
  content: 'WSTECZ';
  position: absolute;
  opacity: 0;
  visibility: hidden;
  left: 0;
  color: #fff;
  transition: all 0.4s;
  bottom: -35px;
  cursor: pointer;
}
section.wpis-section-content .section-heading h1 a:hover:before {
  opacity: 0;
  top: -35px;
}
section.wpis-section-content .section-heading h1 a:hover:after {
  opacity: 1;
  bottom: 0;
  visibility: visible;
}
section.wpis-section-content .section-heading span {
  margin-top: 10px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.wpis-section-content .section-heading span:after {
  margin-top: 15px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(255, 255, 255, 0.8);
}
section.wpis-section-content .content-flexbox {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  width: 90%;
}
section.wpis-section-content .content-flexbox .flexbox-sidebar {
  width: 40%;
}
section.wpis-section-content .content-flexbox .flexbox-sidebar img {
  width: 100%;
}
section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery a {
  margin-top: 5%;
  width: 30%;
  padding-top: 30%;
  overflow: hidden;
  position: relative;
}
section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery a img {
  position: absolute;
  top: 0;
  left: 0;
}
section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery .box-hidden {
  margin: 0;
  padding: 0;
  width: 30%;
  opacity: 0;
  visibility: hidden;
}
section.wpis-section-content .content-flexbox .flexbox-body {
  width: 55%;
  transform: translateY(-5px);
}
section.wpis-section-content .content-flexbox .flexbox-body h2,
section.wpis-section-content .content-flexbox .flexbox-body h3 {
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
}
section.wpis-section-content .content-flexbox .flexbox-body > * {
  margin-top: 15px;
}
section.wpis-section-content .content-flexbox .flexbox-body > *:first-child {
  margin-top: 0;
}
section.wpis-section-content .content-flexbox .flexbox-body p,
section.wpis-section-content .content-flexbox .flexbox-body ol,
section.wpis-section-content .content-flexbox .flexbox-body ul {
  font-weight: 300;
  color: #fff;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.wpis-section-content.realizacja {
  padding-bottom: 80px;
  background: #fff;
}
section.wpis-section-content.realizacja .section-heading h1 a:before {
  content: 'REALIZACJA';
  color: #434343;
}
section.wpis-section-content.realizacja .section-heading h1 a:after {
  color: #434343;
}
section.wpis-section-content.realizacja .section-heading span {
  color: #434343;
}
section.wpis-section-content.realizacja .section-heading span:after {
  background: rgba(0, 0, 0, 0.3);
}
section.wpis-section-content.realizacja .content-flexbox .flexbox-body h2,
section.wpis-section-content.realizacja .content-flexbox .flexbox-body h3 {
  color: #434343;
}
section.wpis-section-content.realizacja .content-flexbox .flexbox-body p,
section.wpis-section-content.realizacja .content-flexbox .flexbox-body ol,
section.wpis-section-content.realizacja .content-flexbox .flexbox-body ul {
  color: #434343;
}
section.kontakt-section-content .section-heading {
  padding-top: 100px;
}
section.kontakt-section-content .section-heading h1 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 10px;
  font-weight: 600;
  line-height: 50px;
}
section.kontakt-section-content .section-heading h2 {
  margin-top: 10px;
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
  display: block;
}
section.kontakt-section-content .section-heading h2:after {
  margin-top: 15px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.kontakt-section-content .content-contact-details {
  margin-top: 80px;
  position: relative;
}
section.kontakt-section-content .content-contact-details .details-background-color {
  position: absolute;
  z-index: -1;
  width: 45%;
  height: 100%;
  top: 0;
  left: 0;
  background: #f2a900;
}
section.kontakt-section-content .content-contact-details .details-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 0;
}
section.kontakt-section-content .content-contact-details .details-info .info-iframe {
  width: 50%;
}
section.kontakt-section-content .content-contact-details .details-info .info-iframe iframe {
  height: 100%;
  min-height: 400px;
  width: 100%;
  border: none;
}
section.kontakt-section-content .content-contact-details .details-info .info-description {
  width: 40%;
}
section.kontakt-section-content .content-contact-details .details-info .info-description h3 {
  color: #333;
  font-size: 28px;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: 600;
  margin-bottom: 10px;
}
section.kontakt-section-content .content-contact-details .details-info .info-description p {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 20px;
}
section.kontakt-section-content .content-contact-details .details-info .info-description .details-list {
  list-style: none;
}
section.kontakt-section-content .content-contact-details .details-info .info-description .details-list .list-item {
  padding: 8px 0;
}
section.kontakt-section-content .content-contact-details .details-info .info-description .details-list .list-item span {
  font-weight: 700;
  margin-bottom: 5px;
}
section.kontakt-section-content .content-contact-details .details-info .info-description .details-list .list-item a {
  text-decoration: none;
  color: #333;
}
section.static-page-section-content {
  padding: 100px 0 80px 0;
}
section.static-page-section-content .section-heading h1 {
  color: #434343;
  font-size: 37px;
  text-transform: uppercase;
  letter-spacing: 12px;
  font-weight: 600;
  line-height: 50px;
}
section.static-page-section-content .section-heading h1:after {
  margin-top: 15px;
  content: '';
  display: block;
  width: 100px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
}
section.static-page-section-content .content-inner {
  margin-top: 50px;
  width: 70%;
}
section.static-page-section-content .content-inner h2,
section.static-page-section-content .content-inner h3 {
  text-transform: uppercase;
  color: #434343;
  letter-spacing: 5px;
  font-size: 17px;
  font-weight: 300;
}
section.static-page-section-content .content-inner > * {
  margin-top: 15px;
}
section.static-page-section-content .content-inner > *:first-child {
  margin-top: 0;
}
section.static-page-section-content .content-inner p,
section.static-page-section-content .content-inner ol,
section.static-page-section-content .content-inner ul {
  font-weight: 300;
  color: #434343;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: 1px;
}
section.section-footer {
  padding: 50px 0;
  background: #232323;
  position: relative;
  overflow: hidden;
}
section.section-footer .footer-logo {
  display: flex;
  justify-content: center;
}
section.section-footer .footer-logo img {
  max-width: 100%;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  margin-top: 50px;
}
section.section-footer .footer-flexbox .flexbox-service p {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 100;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  font-weight: 600;
  padding-bottom: 10px;
  font-size: 16px;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 12px;
  color: #e6e6e5;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #e6e6e5;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 15px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-credits {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 50px;
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits p {
  font-size: 12px;
  color: #e6e6e5;
  font-weight: 300;
}
section.section-footer .footer-credits p a {
  color: #e6e6e5;
}
section.section-footer .alert {
  position: relative;
  margin: 50px 0 0 0;
  padding: 50px 0 0 0;
  border-radius: 0;
  background: none;
  border: none;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}
section.section-footer .alert p {
  font-size: 12px;
  color: #e6e6e5;
  font-weight: 300;
}
section.section-footer .alert p a {
  color: #e6e6e5;
  cursor: pointer;
}
@media (max-width: 1199.98px) {
  .header-slogan {
    display: none;
  }
  section.section-welcome .welcome-content {
    width: 100%;
  }
  section.section-about {
    margin-top: 150px;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner .inner-text {
    letter-spacing: 3px;
    font-size: 15px;
    font-weight: 400;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner.contact p {
    letter-spacing: 3px;
    font-size: 15px;
    font-weight: 400;
  }
  section.section-schiedel {
    padding-top: 100px;
  }
  section.section-news {
    margin-top: 100px;
    padding-top: 100px;
  }
  section.oferta-section-cta .about-image {
    width: 320px;
  }
  section.oferta-section-cta .about-flexbox .flexbox-description p {
    width: 100%;
  }
}
@media (max-width: 991.98px) {
  nav.section-header {
    z-index: 222;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
  nav.section-header .header-flexbox {
    border: none;
  }
  nav.section-header .header-flexbox .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    text-align: center;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    margin-right: 0;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    color: #000;
    font-size: 17px;
    padding: 10px;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
    color: #f2a900;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:hover a {
    transform: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.li-mobile {
    display: block;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.li-mobile ul li a {
    font-size: 15px;
    padding: 7px;
    color: #777;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.li-desktop {
    display: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight a {
    padding: 10px;
    border: none;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight a.active {
    background: none;
    font-weight: 700;
    color: #f2a900;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li.highlight:hover a {
    transform: none;
    background: none;
    color: #000;
  }
  nav.section-header .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  nav.section-header.background-dark {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  nav.section-header.background-dark .header-flexbox {
    border: none;
  }
  section.section-welcome .owl-carousel .item h1,
  section.section-welcome .owl-carousel .item h2 {
    font-size: 50px;
    letter-spacing: 13px;
    line-height: 70px;
  }
  .welcome-owl-nav .list-social li a p {
    display: none;
  }
  section.section-about {
    margin-top: 100px;
  }
  section.section-about .about-image {
    display: none;
  }
  section.section-about .about-flexbox .flexbox-description {
    width: 100%;
    text-align: center;
  }
  section.section-about .about-flexbox .flexbox-description span:after {
    margin: 15px auto 40px auto;
  }
  section.section-about .about-flexbox .flexbox-description p {
    width: 100%;
  }
  section.section-partners .partners-flexbox .flexbox-heading {
    display: none;
  }
  section.section-partners .partners-flexbox .flexbox-owl {
    padding: 70px 0;
    width: 100%;
  }
  section.section-offer .offer-flexbox {
    flex-direction: column;
  }
  section.section-offer .offer-flexbox .flexbox-service-big {
    background: none !important;
    width: 100%;
    padding-top: 0;
  }
  section.section-offer .offer-flexbox .flexbox-service-big .service-text {
    position: relative;
    bottom: auto;
    left: auto;
    width: 100%;
    height: 100%;
    padding: 0px;
    display: block;
    text-align: center;
  }
  section.section-offer .offer-flexbox .flexbox-service-big .service-text h2 {
    color: #434343;
  }
  section.section-offer .offer-flexbox .flexbox-service-big .service-text h2:after {
    margin: 23px auto 0 auto;
    background: #434343;
  }
  section.section-offer .offer-flexbox .flexbox-service-big .service-text p {
    width: 100%;
    color: #777;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper {
    width: 100%;
    margin-top: 60px;
  }
  section.section-schiedel .schiedel-flexbox .flexbox-description {
    width: 100%;
    text-align: center;
  }
  section.section-schiedel .schiedel-flexbox .flexbox-description span:after {
    margin: 15px auto 40px auto;
  }
  section.section-schiedel .schiedel-flexbox .flexbox-description p {
    width: 100%;
  }
  section.section-schiedel .schiedel-flexbox .flexbox-image {
    display: none;
  }
  section.section-news .section-heading {
    text-align: center;
  }
  section.section-news .section-heading h2 a {
    padding: 0;
  }
  section.section-news .section-heading h2 a:after {
    content: none;
  }
  section.section-news .section-heading span:after {
    margin: 15px auto 0 auto;
  }
  section.section-news .flexbox-news .news-service {
    width: 47%;
  }
  section.section-news .flexbox-news .news-service:last-child {
    display: none;
  }
  section.o-nas-section-about-content .about-content-info {
    padding: 80px 0;
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox {
    width: 100%;
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body {
    width: 100%;
    text-align: center;
    transform: translateY(0);
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body p {
    font-size: 13px;
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-sidebar {
    display: none;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .section-heading {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 70px;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .section-heading h2 {
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 6px;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .inner-description {
    margin-top: 50px;
    text-align: center;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .inner-description p {
    width: 100%;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .gallery-flexbox {
    padding-right: 0;
  }
  section.o-nas-section-about-content .about-team {
    padding: 70px 0;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper {
    flex-direction: column;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description {
    width: 100%;
    text-align: center;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description span:after {
    margin: 15px auto 40px auto;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox {
    margin-top: 50px;
    width: 100%;
  }
  section.o-nas-section-about-content .about-gallery .section-heading {
    text-align: center;
  }
  section.o-nas-section-about-content .about-gallery .section-heading span:after {
    margin: 15px auto 40px auto;
  }
  section.o-nas-section-about-content .about-partners .section-heading {
    text-align: center;
  }
  section.o-nas-section-about-content .about-partners .section-heading span:after {
    margin: 15px auto 40px auto;
  }
  section.oferta-section-content {
    padding-bottom: 40px;
  }
  section.oferta-section-content .section-heading {
    text-align: center;
  }
  section.oferta-section-content .section-heading span:after {
    margin: 15px auto 0 auto;
  }
  section.oferta-section-content .content-flexbox {
    width: 100%;
  }
  section.oferta-section-content .content-flexbox .flexbox-body {
    width: 100%;
    text-align: center;
    transform: translateY(0);
  }
  section.oferta-section-content .content-flexbox .flexbox-sidebar {
    display: none;
  }
  section.oferta-section-content.white .img-absolute {
    display: none;
  }
  section.oferta-section-cta {
    padding-top: 0;
  }
  section.oferta-section-cta .about-image {
    left: auto;
    bottom: auto;
    position: relative;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    transform: translateY(-70px);
  }
  section.oferta-section-cta .about-flexbox .flexbox-description {
    width: 100%;
    text-align: center;
  }
  section.oferta-section-cta .about-flexbox .flexbox-description span:after {
    margin: 15px auto 40px auto;
  }
  section.oferta-section-cta .about-flexbox .flexbox-description p {
    width: 100%;
  }
  section.oferta-section-cta.white {
    background: #fff;
  }
  section.oferta-section-cta.white h2,
  section.oferta-section-cta.white span,
  section.oferta-section-cta.white p {
    color: #434343 !important;
  }
  section.oferta-section-cta.white span:after {
    background: rgba(0, 0, 0, 0.3) !important;
  }
  section.realizacje-section-content .section-heading {
    text-align: center;
  }
  section.realizacje-section-content .section-heading span:after {
    margin: 15px auto 0 auto;
  }
  section.realizacje-section-content .section-heading p {
    width: 100%;
  }
  section.realizacje-section-content .content-category {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.realizacje-section-content .content-category p {
    margin-right: 0;
  }
  section.realizacje-section-content .content-category ul {
    margin: 30px;
    justify-content: center;
  }
  section.realizacje-section-content .content-flexbox {
    flex-direction: column;
  }
  section.realizacje-section-content .content-flexbox .flexbox-body {
    width: 100%;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-heading {
    text-align: center;
    margin-right: 0;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar .description {
    text-align: center;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar .contact {
    text-align: center;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar .contact a {
    display: block;
  }
  section.wpis-section-content .section-heading {
    text-align: center;
  }
  section.wpis-section-content .section-heading h1 a:before {
    left: 50%;
    transform: translateX(-50%);
  }
  section.wpis-section-content .section-heading h1 a:after {
    left: 50%;
    transform: translateX(-50%);
  }
  section.wpis-section-content .section-heading span:after {
    margin: 15px auto 0 auto;
  }
  section.wpis-section-content .content-flexbox {
    width: 100%;
  }
  section.static-page-section-content .content-inner {
    width: 100%;
  }
  section.section-footer .footer-flexbox {
    flex-wrap: wrap;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    width: calc(100% / 3);
  }
  section.section-footer .footer-flexbox .flexbox-service .list-social {
    justify-content: flex-start;
  }
}
@media (max-width: 767.98px) {
  .section-header-wrapper {
    min-height: calc(100vh - 70px);
  }
  .section-header-wrapper .header-background {
    background: url(../img/backgrounds/fotolia_279557805.jpg) center;
    background-size: cover;
  }
  .section-header-wrapper .header-background .owl-carousel-welcome {
    display: none;
  }
  section.section-welcome .welcome-content .content-inner {
    display: block;
    text-align: center;
  }
  section.section-welcome .welcome-content .content-inner h1,
  section.section-welcome .welcome-content .content-inner h2 {
    color: #fff;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 10px;
    font-weight: 700;
    line-height: 60px;
  }
  section.section-welcome .welcome-content .content-inner p {
    width: 100%;
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 4px;
  }
  section.section-welcome .welcome-content .content-inner p:before {
    content: '';
    display: block;
    width: 80px;
    height: 1px;
    margin: 30px auto;
    background: rgba(255, 255, 255, 0.8);
  }
  section.section-welcome .owl-carousel {
    display: none;
  }
  .welcome-owl-nav {
    display: none;
  }
  .welcome-cooperate {
    margin-top: 70px;
  }
  .welcome-cooperate .cooperate-inner {
    justify-content: space-between;
  }
  .welcome-cooperate .cooperate-inner .inner-service {
    height: 200px;
    width: 47%;
    margin-right: 0px;
  }
  section.section-about {
    margin-top: 70px;
    padding: 70px 0;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner {
    width: calc(100% / 2);
    padding-top: calc(100% / 2);
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner .inner-text {
    left: 15px;
    bottom: 12px;
  }
  section.wpis-section-content .content-flexbox {
    flex-direction: column;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar img {
    width: 100%;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery a {
    margin-top: 5%;
    width: 30%;
    padding-top: 30%;
    overflow: hidden;
    position: relative;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery a img {
    position: absolute;
    top: 0;
    left: 0;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery .box-hidden {
    margin: 0;
    padding: 0;
    width: 30%;
    opacity: 0;
    visibility: hidden;
  }
  section.wpis-section-content .content-flexbox .flexbox-body {
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .section-heading {
    text-align: center;
  }
  section.kontakt-section-content .section-heading span:after {
    margin: 15px auto 0 auto;
  }
  section.kontakt-section-content .content-contact-details .details-background-color {
    display: none;
  }
  section.kontakt-section-content .content-contact-details .details-info {
    padding: 0;
    flex-direction: column-reverse;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-iframe {
    margin-top: 50px;
    width: 100%;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description h3 {
    color: #434343;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description p {
    color: #777;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description .details-list {
    list-style: none;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description .details-list .list-item {
    padding: 8px 0;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description .details-list .list-item span {
    color: #434343;
  }
  section.kontakt-section-content .content-contact-details .details-info .info-description .details-list .list-item a {
    color: #f2a900;
    font-weight: 700;
  }
  section.static-page-section-content .section-heading {
    text-align: center;
  }
  section.static-page-section-content .section-heading h1:after {
    margin: 15px auto 0 auto;
  }
  section.static-page-section-content .content-inner h2,
  section.static-page-section-content .content-inner h3 {
    text-align: center;
  }
}
@media (max-width: 575.98px) {
  .pagination-nav {
    margin-top: 60px;
  }
  section.section-welcome .welcome-content .content-inner h1,
  section.section-welcome .welcome-content .content-inner h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.section-welcome .welcome-content .content-inner p {
    font-size: 15px;
    letter-spacing: 2px;
  }
  section.section-about .about-flexbox .flexbox-description h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.section-about .about-flexbox .flexbox-description span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.section-schiedel {
    padding-top: 70px;
  }
  section.section-schiedel .schiedel-flexbox .flexbox-description h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.section-schiedel .schiedel-flexbox .flexbox-description span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.section-news {
    margin-top: 70px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  section.section-news .section-heading h1,
  section.section-news .section-heading h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.section-news .section-heading span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.section-news .flexbox-news {
    flex-direction: column;
  }
  section.section-news .flexbox-news .news-service {
    height: 350px;
    width: 100%;
  }
  section.o-nas-section-about-content .about-content-info .section-heading {
    text-align: center;
  }
  section.o-nas-section-about-content .about-content-info .section-heading h1 {
    font-size: 26px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.o-nas-section-about-content .about-content-info .section-heading h2 {
    margin-top: 10px;
    font-size: 17px;
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox {
    width: 100%;
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-body {
    width: 100%;
    text-align: center;
    transform: translateY(0);
  }
  section.o-nas-section-about-content .about-content-info .content-flexbox .flexbox-sidebar {
    display: none;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .gallery-flexbox a {
    margin-top: 3%;
    width: 31%;
    padding-top: 31%;
  }
  section.o-nas-section-about-content .about-certificates .certificates-inner .gallery-flexbox .box-hidden {
    width: 31%;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-description span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox {
    margin-top: 0;
    flex-direction: column;
    align-items: center;
  }
  section.o-nas-section-about-content .about-team .team-flexbox-wrapper .team-flexbox .flexbox-service {
    margin-top: 30px;
    width: 100%;
    max-width: 200px;
    text-align: center;
  }
  section.o-nas-section-about-content .about-gallery {
    padding: 60px 0 70px 0;
  }
  section.o-nas-section-about-content .about-gallery .section-heading h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.o-nas-section-about-content .about-gallery .section-heading span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.o-nas-section-about-content .about-gallery .gallery-flexbox a {
    margin-top: 3%;
    width: 31%;
    padding-top: 31%;
  }
  section.o-nas-section-about-content .about-gallery .gallery-flexbox .box-hidden {
    width: 31%;
  }
  section.o-nas-section-about-content .about-partners {
    padding: 60px 0 70px 0;
  }
  section.o-nas-section-about-content .about-partners .section-heading h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.o-nas-section-about-content .about-partners .section-heading h3 {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.oferta-section-content {
    padding-top: 70px;
  }
  section.oferta-section-content .section-heading h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.oferta-section-content .section-heading span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.oferta-section-content .content-flexbox {
    margin-top: 50px;
  }
  section.oferta-section-content .content-flexbox .flexbox-body h2 {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.oferta-section-cta .about-flexbox .flexbox-description h2 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.oferta-section-cta .about-flexbox .flexbox-description span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.oferta-section-gallery .gallery-flexbox a {
    margin-top: 3%;
    width: 31%;
    padding-top: 31%;
  }
  section.oferta-section-gallery .gallery-flexbox .box-hidden {
    width: 31%;
  }
  section.realizacje-section-content {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  section.realizacje-section-content .section-heading h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.realizacje-section-content .section-heading span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.realizacje-section-content .content-category p {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.realizacje-section-content .content-category ul {
    margin-top: 20px;
  }
  section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news {
    margin-top: 30px;
  }
  section.realizacje-section-content .content-flexbox .flexbox-body .flexbox-news .news-service {
    margin-top: 6% !important;
    width: 100%;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar {
    margin-top: 40px;
  }
  section.realizacje-section-content .content-flexbox .flexbox-sidebar .sidebar-heading {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.wpis-section-content {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  section.wpis-section-content .section-heading h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.wpis-section-content .section-heading span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.wpis-section-content .content-flexbox {
    margin-top: 60px;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery a {
    margin-top: 2%;
    width: 32%;
    padding-top: 32%;
  }
  section.wpis-section-content .content-flexbox .flexbox-sidebar .sidebar-gallery .box-hidden {
    width: 32%;
  }
  section.wpis-section-content .content-flexbox .flexbox-body h2,
  section.wpis-section-content .content-flexbox .flexbox-body h3 {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.wpis-section-content.realizacja {
    padding-bottom: 60px;
  }
  section.kontakt-section-content .section-heading {
    padding-top: 70px;
  }
  section.kontakt-section-content .section-heading h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.kontakt-section-content .section-heading span {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.kontakt-section-content .content-contact-details {
    margin-top: 50px;
  }
  section.static-page-section-content {
    padding: 70px 0 60px 0;
  }
  section.static-page-section-content .section-heading h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 45px;
  }
  section.static-page-section-content .content-inner h2,
  section.static-page-section-content .content-inner h3 {
    letter-spacing: 2px;
    font-size: 15px;
  }
  section.section-footer .footer-logo img {
    max-width: 260px;
    width: 100%;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    margin-top: 50px;
    width: 100%;
  }
  section.section-footer .footer-flexbox .flexbox-service .list-social {
    justify-content: center;
  }
  section.section-footer .footer-credits {
    text-align: center;
    justify-content: center;
  }
  section.section-footer .alert {
    text-align: center;
  }
}
@media (max-width: 400px) {
  .welcome-cooperate .cooperate-inner {
    flex-direction: column;
  }
  .welcome-cooperate .cooperate-inner .inner-service {
    width: 100%;
    height: 130px;
  }
  .welcome-cooperate .cooperate-inner .inner-service:nth-child(2) {
    margin-top: 30px;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner {
    width: 100%;
    padding-top: 50%;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner .inner-text {
    left: 0;
    bottom: 12px;
    text-align: center;
    width: 100%;
  }
  section.section-offer .offer-flexbox .flexbox-service-wrapper .service-inner:not(:first-child) {
    margin-top: 30px;
  }
}
