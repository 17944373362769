@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,600,700,800&display=swap&subset=latin-ext');

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Poppins', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	ul, ol, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}

	.btn {
		padding: 10px 30px;	
		border-radius: 0;
		font-size: 16px;
		font-weight: 300;
		cursor: pointer;
		transition: all 0.3s;

		&.btn-default {
			border: 1px solid #fff;
			background: none;
			color: #fff;

			&:hover {
				background: #fff;
				color: #666;
			}
		}
	}
}

@color1: #f2a900;

.system-alerts {
	width: 100%;
	text-align: center;

	.alert {
		border-radius: 0;
		border: none;
		height: 60px;
		display: flex;
		align-items: center;
		opacity: 0.9;
		transition: all 0.4s;

		.alert-inner {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.img-hidden {
				visibility: hidden;
				opacity: 0;
				height: 18px;
			}

			p {
				color: #fff;
				padding: 0 50px;
			}

			button {
				color: #fff;
				opacity: 1;
				font-weight: 300;
				float: none;

				img {
					height: 18px;
				}
			}
		}

		&.alert-success {
			background: #76ad21;
		}

		&.alert-info {
			background: #218ead;
		}

		&.alert-warning {
			background: #c52b20;
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				
				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.section-header-wrapper {
	position: relative;
	overflow: hidden;
	min-height: calc(~'100vh - 100px');
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.header-background {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		.owl-carousel-welcome {
			height: 100%;

			.owl-stage, .owl-stage-outer, .owl-item {
				height: 100%;
			}

			.item {
				width: 100%;
				height: 100%;
				// position: absolute;
				// top: 0;
				// left: 0;
				// min-height: calc(~'100vh - 100px');

				&.header-background-animation {
					// animation-name: kenburns;
					// animation-duration: 50s;
					// animation-iteration-count: infinite;
				}
			}
		}
	}

	@keyframes kenburns {
		0% { 
			transform: scale(1);
		}
		50% { 
			transform: scale(1.15);
		}
		100% {
			transform: scale(1);
		}
	}

	.container {
		position: relative;
	}
}

.header-slogan {
	width: 100%;
	position: absolute;
	z-index: 111;
	right: 0;
	bottom: 180px;

	.slogan-inner {
		display: flex;
		justify-content: flex-end;

		.inner-service {
			transform: rotate(90deg) translateY(100%);
			transform-origin: right bottom;
			position: relative;

			span {
				font-weight: 300;
				color: #fff;
				font-size: 14px;
				letter-spacing: 2px;
				text-transform: uppercase;
			}

			p {
				margin-top: 10px;
				font-weight: 700;
				color: #fff;
				font-size: 21px;
				letter-spacing: 2px;
			}

			&:after {
				content: '';
				height: 2px;
				width: 40px;
				background: #fff;
				bottom: -20px;
				left: -30px;
				display: block;
				position: absolute;
			}

			&:before {
				content: '';
				width: 2px;
				height: 40px;
				background: #fff;
				bottom: -20px;
				left: -30px;
				display: block;
				position: absolute;
			}
		}
	}

	&.dark {
		.slogan-inner {
			.inner-service {
				span {
					color: #434343;
				}

				p {
					color: #434343;
				}

				&:after {
					background: #434343;
				}

				&:before {
					background: #434343;
				}
			}
		}
	}
}

.pagination-nav {
	margin-top: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	li {
		a {
			display: block;
			padding: 10px;
			color: #fff;
			text-transform: uppercase;
			transition: all 0.4s;

			&:hover {
				color: @color1;
			}
		}

		&.active {
			a {
				font-weight: 700;
				color: @color1;
				font-size: 28px;
			}
		}
	}
}

nav.section-header {
	position: relative;
	z-index: 222;

	.header-flexbox {
		padding: 25px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid rgba(0, 0, 0, 0.3);

		.flexbox-logo {
			position: relative;

			.logo-white {
				height: 50px;
				opacity: 0;
			}

			.logo-absolute {
				top: 0;
				left: 0;
				opacity: 1;
				position: absolute;
			}
		}

		.flexbox-nav-wrapper {
			.nav-button-close {
				position: absolute;
				display: none;
				width: 100%;
				top: 20px;
				left: 0;

				.container {
					display: flex;
					justify-content: flex-end;

					&:before, &:after {
						content: none;
					}
				}

				button {
					outline: none;
					border: none;
					background: none;
					padding: 15px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					.button-bar {
						display: block;
						position: absolute;
						width: 30px;
						height: 2px;
						background: #222;

						&:nth-child(1) {
							transform: rotate(45deg);
						}

						&:nth-child(2) {
							transform: rotate(-45deg);
						}
					}
				}
			}

			.flexbox-nav-inner {
				.flexbox-nav {
					display: flex;
					align-items: center;

					li {
						margin-right: 40px;

						a {
							color: #333;
							display: block;
							font-weight: 300;
							transition: all 0.4s;

							&.active {
								font-weight: 700;
							}
						}

						&:hover {
							a {
								transform: translateY(-3px);

								&.active {
									transform: none;
								}
							}
						}

						.dropdown {
							.dropdown-menu {
								margin-top: 62px;
								padding: 10px 10px;
								min-width: 12rem;
								background: rgba(0, 0, 0, 0.8);
								border-radius: 0;
								border: none;
								position: relative;

								.dropdown-item {
									padding: 7px;
									font-size: 14px;
									transition: all 0.3s;
									transform: none !important;
									color: #fff;

									&:hover {
										background: rgba(255, 255, 255, 0.1);
									}

									&.highlight {
										background: @color1;
										margin-bottom: 4px;
										font-weight: 500;

										&:hover {
											background: darken(@color1, 10%);
										}
									}
								}

								&:before {
									content: '';
									display: block;
									position: absolute;
									left: 22px;
									top: -5px;
									width: 0;
									height: 0;
									border-style: solid;
									border-width: 0 4px 5px 4px;
									border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
								}
							}
						}

						@media (min-width: 992px) {
							.animate {
								animation-duration: 0.5s;
								-webkit-animation-duration: 0.5s;
								animation-fill-mode: both;
								-webkit-animation-fill-mode: both;
							}
						}

						@keyframes slideIn {
							0% {
								transform: translateY(-50px);
								opacity: 0;
							}

							100% {
								transform:translateY(0rem);
								opacity: 1;
							}

							0% {
								transform: translateY(-50px);
								opacity: 0;
							}
						}

						@-webkit-keyframes slideIn {
							0% {
								-webkit-transform: transform;
								-webkit-opacity: 0;
							}

							100% {
								-webkit-transform: translateY(0);
								-webkit-opacity: 1;
							}

							0% {
								-webkit-transform: translateY(-50px);
								-webkit-opacity: 0;
							}
						}

						.slideIn {
							-webkit-animation-name: slideIn;
							animation-name: slideIn;
						}

						&.li-mobile {
							display: none;
						}

						&.highlight {
							a {
								padding: 10px 20px;
								border: 1px solid #333;

								&.active {
									background: #333;
									color: #fff;
									font-weight: 400;
								}
							}

							&:hover {
								a {
									transform: none;
									background: #333;
									color: #fff;
								}
							}
						}

						&:last-child {
							margin-right: 0;
						}
					}
				}
			}
		}

		.nav-button {
			padding: 10px;
			border: none;
			background: none;
			flex-direction: column;
			align-items: flex-end;
			justify-content: center;
			outline: none;
			display: none;
			cursor: pointer;
			
			.button-bar {
				height: 2px;
				width: 24px;
				background: #434343;
				border-radius: 10px;
				transition: all 0.4s;

				&:nth-child(2) {
					margin-top: 4px;
					width: 27px;
				}

				&:nth-child(3) {
					margin-top: 4px;
					width: 30px;
				}
			}

			&:hover, &:focus {
				.button-bar {
					width: 30px !important;
				}
			}
		}
	}

	&.background-dark {
		.header-flexbox {
			border-bottom: 1px solid rgba(255, 255, 255, 0.5);

			.nav-button {
				.button-bar {
					background: #fff;
				}
			}
		}

		.flexbox-logo {
			.logo-absolute {
				opacity: 0;
			}

			.logo-white {
				opacity: 1;
			}
		}

		.flexbox-nav-wrapper {
			.flexbox-nav-inner {
				.flexbox-nav {
					li {
						a {
							color: #fff;
						}

						&.highlight {
							a {
								border: 1px solid #fff;

								&.active {
									background: #fff;
								}
							}

							&:hover {
								a {
									background: #fff;
									color: #666;
								}
							}
						}
					}
				}
			}
		}
	}
}

section.section-welcome {
	position: relative;
	z-index: 111;

	.welcome-content {
		width: 70%;
		padding: 100px 0;

		.content-inner {
			display: none;
		}
	}

	.owl-carousel {
		.item {
			h1, h2 {
				color: #fff;
				font-size: 60px;
				text-transform: uppercase;
				letter-spacing: 15px;
				font-weight: 700;
				line-height: 80px;
			}

			p {
				width: 80%;
				margin-top: 30px;
				text-transform: uppercase;
				color: #fff;
				display: flex;
				font-size: 17px;
				font-weight: 300;
				letter-spacing: 5px;

				&:before {
					content: '';
					margin-right: 20px;
					display: block;
					min-width: 80px;
					height: 1px;
					transform: translateY(10px);
					background: rgba(255, 255, 255, 0.8);
				}
			}
		}

		.owl-controls {
			display: none;
		}
	}
}

.welcome-bottom-wrapper {
	position: relative;
	z-index: 111;
}

.welcome-owl-nav {
	position: absolute;
	z-index: 112;
	top: 135px;
	left: 0;
	width: 100%;

	.owl-custom-nav {
		display: none;
		align-items: center;

		.owl-nav-link {
			height: 30px;
			width: 30px;
			display: block;
			border: 0.5px solid #000;
			border-radius: 50%;
			margin-right: 20px;
			position: relative;

			&.active {
				&:after {
					content: '';
					height: 7px;
					width: 7px;
					border-radius: 50%;
					background: @color1;
					position: absolute;
					display: block;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					transition: all 0.4s;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.list-social {
		display: flex;
		align-items: center;

		li {
			margin-right: 30px;

			a {
				display: flex;
				align-items: center;

				img {
					height: 27px;
					margin-right: 10px;
					filter: grayscale(50%);
					transition: filter 0.4s;
				}

				p {
					font-size: 13px;
					letter-spacing: 1.5px;
					text-transform: uppercase;
					color: #777;
					font-weight: 300;
				}

				&:hover {
					img {
						filter: none;
					}
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.welcome-cooperate {
	position: relative;
	width: 100%;
	margin-top: -100px;

	.cooperate-inner {
		display: flex;
		justify-content: flex-end;

		.inner-service {
			height: 200px;
			width: 200px;
			display: block;
			background: #333;
			margin-right: 50px;
			box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
			transition: all 0.3s;

			&:last-child {
				margin-right: 0;
			}

			&:hover {
				box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.22);
			}
		}
	}
}

section.section-about {
	margin-top: 150px;
	padding: 80px 0;
	background: #516b76;
	background: #726b5c;
	position: relative;

	.container {
		position: relative;
	}

	.about-image {
		position: absolute;
		left: 15px;
		bottom: 0;
	}

	.about-flexbox {
		display: flex;
		justify-content: flex-end;

		.flexbox-description {
			width: 60%;

			h2 {
				color: #fff;
				font-size: 37px;
				text-transform: uppercase;
				letter-spacing: 12px;
				font-weight: 600;
				line-height: 50px;
			}

			h3 {
				margin-top: 10px;
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				display: block;

				&:after {
					margin-top: 15px;
					margin-bottom: 40px;
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: rgba(255, 255, 255, 0.8);
				}
			}

			p {
				width: 100%;
				margin-top: 15px;
				font-weight: 300;
				color: #fff;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 1px;
			}

			.btn {
				margin-top: 20px;
			}
		}
	}
}

section.section-partners {
	.partners-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-heading {
			width: 23%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: @color1;

			p {
				padding: 10px 30px;
				color: #fff;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				text-transform: uppercase;
			}
		}

		.flexbox-owl {
			padding: 20px 0;
			width: 70%;

			.owl-carousel {
				.owl-stage {
					display: flex;
					align-items: center;
				}

				.item {
					img {
						opacity: 0.5;
						transition: all 0.3s;

						&:hover {
							opacity: 1;
						}
					}
				}

				.owl-controls {
					display: none;
				}
			}
		}
	}
}

section.section-offer {
	.offer-flexbox {
		display: flex;

		.flexbox-service-big {
			width: 40%;
			padding-top: 40%;
			position: relative;

			.service-text {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding: 20px;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				h2 {
					letter-spacing: 5px;
					font-size: 32px;
					color: #fff;
					font-weight: 600;
					text-transform: uppercase;

					&:after {
						margin-top: 23px;
						content: '';
						display: block;
						width: 100px;
						height: 1px;
						background: #fff;
					}
				}

				p {
					width: 90%;
					margin-top: 20px;
					font-weight: 300;
					color: #fff;
					font-size: 14px;
					line-height: 26px;
					letter-spacing: 1px;
				}
			}
		}

		.flexbox-service-wrapper {
			width: 60%;
			display: flex;
			flex-wrap: wrap;

			.service-inner {
				width: calc(100% / 3);
				padding-top: calc(100% / 3);
				position: relative;
				filter: grayscale(30%);

				&:after {
					position: absolute;
					content: '';
					bottom: 0;
					left: 0;
					width: 100%;
					height: 40%;
					transition: all 0.3s;
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );
				}

				.inner-text {
					position: absolute;
					bottom: 15px;
					left: 25px;
					letter-spacing: 5px;
					font-size: 16px;
					color: #fff;
					font-weight: 300;
					text-transform: uppercase;
					z-index: 111;
				}

				&.contact {
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					padding: 20px;

					&:after {
						content: none;
					}

					p {
						letter-spacing: 5px;
						font-size: 17px;
						color: #333;
						font-weight: 300;
						text-transform: uppercase;
						transition: all 0.3s;

						&:before {
							margin: 0 auto;
							margin-bottom: 23px;
							content: '';
							display: block;
							width: 60px;
							height: 1px;
							background: #333;
							transition: all 0.3s;
						}
					}

					&:hover {
						p {
							transform: translateY(-12px);

							&:before {
								width: 0;
							}
						}
					}
				}

				&:hover {
					&:after {
						height: 100%;
					}
				}
			}
		}
	}
}

section.section-schiedel {
	padding-top: 120px;

	.schiedel-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.flexbox-description {
			width: 60%;

			h2 {
				color: #434343;
				font-size: 37px;
				text-transform: uppercase;
				letter-spacing: 12px;
				font-weight: 600;
				line-height: 50px;
			}

			span {
				margin-top: 10px;
				text-transform: uppercase;
				color: #434343;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				display: block;

				&:after {
					margin-top: 15px;
					margin-bottom: 40px;
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: rgba(0, 0, 0, 0.3);
				}
			}

			p {
				width: 90%;
				margin-top: 15px;
				font-weight: 300;
				color: #777;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 1px;
			}

			.btn {
				margin-top: 20px;
			}
		}

		.flexbox-image {
			width: 35%;

			img {
				width: 100%;
			}
		}
	}
}

section.section-news {
	background: #232323;
	background: url(../img/backgrounds/news-background.jpg);
	background-size: cover;
	background-position: center bottom;
	margin-top: -150px;
	padding-top: 200px;
	padding-bottom: 100px;

	&.subpage {
		margin-top: 0;
		padding-top: 100px;
		padding-bottom: 40px;
	}

	.section-heading {
		h1, h2 {
			color: #fff;
			font-size: 37px;
			text-transform: uppercase;
			letter-spacing: 12px;
			font-weight: 600;
			line-height: 50px;
			position: relative;

			a {
				color: #fff;
				padding-right: 100px;
				display: block;

				&:after {
					content: 'Zobacz całą bazę wiedzy';
					position: absolute;
					font-size: 13px;
					letter-spacing: 2px;
					opacity: 0;
					transition: all 0.4s;
					bottom: -35px;
				}

				&:hover {
					&:after {
						opacity: 0.5;
						bottom: -9px;
					}
				}
			}
		}

		h3 {
			margin-top: 10px;
			text-transform: uppercase;
			color: #fff;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
			display: block;

			&:after {
				margin-top: 15px;
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				background: rgba(255, 255, 255, 0.8);
			}
		}
	}

	.flexbox-news {
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.news-service {
			margin-top: 60px;
			height: 420px;
			width: 30%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			transition: all 0.3s;

			.service-inner {
				width: 90%;
				padding: 15px;
				padding-left: 0;
				min-height: 80px;
				background: #232323;

				h3 {
					color: #fff;
					font-size: 14px;
					text-transform: uppercase;
					letter-spacing: 3px;
					font-weight: 600;
				}
			}

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

section.o-nas-section-about-content {
	.about-content-info {
		position: relative;
		padding: 100px 0 130px 0;
		background: #726b5c;

		.section-heading {
			h1 {
				color: #fff;
				font-size: 37px;
				text-transform: uppercase;
				letter-spacing: 10px;
				font-weight: 600;
				line-height: 50px;
			}
	
			h2 {
				margin-top: 10px;
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				display: block;
				&:after {
					margin-top: 15px;
					margin-bottom: 40px;
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: rgba(255, 255, 255, 0.8);
				}
			}
		}
	
		.content-flexbox {
			position: relative;
			display: flex;
			justify-content: space-between;
			width: 90%;
	
			.flexbox-body {
				width: 55%;
				transform: translateY(-5px);
	
				p {
					font-weight: 300;
					color: #fff;
					font-size: 14px;
					line-height: 26px;
					letter-spacing: 1px;
					margin-top: 15px;
				}
	
				> * {
					margin-top: 15px;
	
					&:first-child {
						margin-top: 0;
					}
				}
			}
	
			.flexbox-sidebar {
				width: 40%;
	
				img {
					width: 100%;
				}
			}
		}
	}

	.about-certificates {
		.container {
			position: relative;
		}

		.certificates-inner {
			position: relative;
			width: 100%;

			.section-heading {
				margin-top: -30px;
				background: @color1;
				padding: 40px;
				display: inline-block;

				h2 {
					font-size: 40px;
					line-height: 50px;
					color: #fff;
					font-weight: 600;
					letter-spacing: 7px;
					text-transform: uppercase;
				}
			}

			.inner-description {
				margin-top: 50px;

				p {
					width: 60%;
					margin-top: 15px;
					font-weight: 300;
					color: #777;
					font-size: 14px;
					line-height: 26px;
					letter-spacing: 1px;

					&:first-child {
						margin-top: 0;
					}
				}
			}

			.gallery-flexbox {
				margin-top: 35px;
				padding-right: 30%;

				a {
					margin-top: 2%;
					width: 23.5%;
					padding-top: 23.5%;
					box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.08);
				}

				.box-hidden {
					width: 23.5%;
				}
			}

			.inner-certificates-slogan {
				width: 38%;
				height: 10%;
				position: absolute;
				right: 0;
				bottom: 0;
				top: 70%;

				.slogan-inner {
					display: flex;
					justify-content: flex-end;
		
					.inner-service {
						transform: rotate(90deg) translateY(100%);
						transform-origin: right bottom;
						position: relative;
		
						span {
							font-weight: 300;
							color: #333;
							font-size: 14px;
							letter-spacing: 2px;
							text-transform: uppercase;
						}
		
						p {
							margin-top: 10px;
							font-weight: 700;
							color: #333;
							font-size: 21px;
							letter-spacing: 2px;
						}
		
						&:after {
							content: '';
							height: 2px;
							width: 40px;
							background: #232323;
							bottom: -20px;
							left: -30px;
							display: block;
							position: absolute;
						}

						&:before {
							content: '';
							width: 2px;
							height: 40px;
							background: #232323;
							bottom: -20px;
							left: -30px;
							display: block;
							position: absolute;
						}
					}
				}
			}
		}
	}

	.about-team {
		padding: 100px 0;

		.team-flexbox-wrapper {
			display: flex;
			justify-content: space-between;

			.team-description {
				width: 35%;

				h2 {
					color: #434343;
					font-size: 37px;
					text-transform: uppercase;
					letter-spacing: 10px;
					font-weight: 600;
					line-height: 50px;
				}

				h3 {
					margin-top: 10px;
					text-transform: uppercase;
					color: #434343;
					letter-spacing: 5px;
					font-size: 17px;
					font-weight: 300;
					display: block;
					
					&:after {
						margin-top: 15px;
						margin-bottom: 40px;
						content: '';
						display: block;
						width: 100px;
						height: 1px;
						background: rgba(0, 0, 0, 0.3);
					}
				}

				p {
					margin-top: 15px;
					font-weight: 300;
					color: #777;
					font-size: 14px;
					line-height: 26px;
					letter-spacing: 1px;
				}
			}

			.team-flexbox {
				width: 60%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.flexbox-service {
					width: 31%;

					.service-image {
						width: 100%;
						padding-top: 100%;
					}

					.service-description {
						h3 {
							margin: 10px 0 5px 0; 
							color: #434343;
							font-size: 18px;
							text-transform: uppercase;
							letter-spacing: 2;
							font-weight: 600;
						}

						p {
							font-size: 13px;
							color: #777;
							font-weight: 500;
						}
					}
				}
			}
		}
	}

	.about-gallery {
		background-color: #242424;
		padding: 80px 0;

		.section-heading {
			h2 {
				color: #fff;
				font-size: 37px;
				text-transform: uppercase;
				letter-spacing: 10px;
				font-weight: 600;
				line-height: 50px;
			}

			h3 {
				margin-top: 10px;
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				display: block;
				
				&:after {
					margin-top: 15px;
					margin-bottom: 40px;
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: rgba(255, 255, 255, 0.8);
				}
			}
		}
	}

	.about-partners {
		padding: 80px 0;

		.section-heading {
			h2 {
				color: #434343;
				font-size: 37px;
				text-transform: uppercase;
				letter-spacing: 10px;
				font-weight: 600;
				line-height: 50px;
			}

			h3 {
				margin-top: 10px;
				text-transform: uppercase;
				color: #434343;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				display: block;
				&:after {
					margin-top: 15px;
					margin-bottom: 40px;
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: rgba(0, 0, 0, 0.3);
				}
			}
		}

		.owl-carousel {
			.owl-stage {
				display: flex;
				align-items: center;
			}

			.item {
				img {
					opacity: 0.5;
					transition: all 0.3s;

					&:hover {
						opacity: 1;
					}
				}
			}

			.owl-controls {
				display: none;
			}
		}
	}
}

section.oferta-section-content {
	padding-top: 100px;
	padding-bottom: 80px;

	.section-heading {
		position: relative;

		h1 {
			color: #434343;
			font-size: 37px;
			text-transform: uppercase;
			letter-spacing: 12px;
			font-weight: 600;
			line-height: 50px;
		}

		h2 {
			margin-top: 10px;
			text-transform: uppercase;
			color: #434343;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
			display: block;

			&:after {
				margin-top: 15px;
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				background: rgba(0, 0, 0, 0.3);
			}
		}

		p {
			width: 70%;
			margin-top: 15px;
			font-weight: 300;
			color: #777;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 1px;
		}
	}

	.content-flexbox {
		position: relative;
		margin-top: 80px;
		display: flex;
		justify-content: space-between;
		width: 90%;

		.flexbox-body {
			width: 55%;
			transform: translateY(-5px);

			h2, h3 {
				text-transform: uppercase;
				color: #434343;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
			}

			> * {
				margin-top: 15px;

				&:first-child {
					margin-top: 0;
				}
			}

			p, ol, ul {
				font-weight: 300;
				color: #434343;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 1px;
			}

			ol, ul {
				padding-left: 17px;
			}
		}

		.flexbox-sidebar {
			width: 40%;

			img {
				width: 100%;
			}
		}
	}

	&.white {
		position: relative;

		.img-absolute {
			position: absolute;
			width: 70%;
			top: 200px;
			right: 80px;
			opacity: 0.15;
		}

		* {
			color: #fff !important;
		}

		h2:after {
			background: rgba(255, 255, 255, 0.8) !important;
		}
	}
}

section.oferta-section-cta {
	margin-top: 100px;
	padding: 80px 0;
	background: #726b5c;
	position: relative;

	.container {
		position: relative;
	}

	.about-image {
		position: absolute;
		left: 15px;
		bottom: 0;
		width: 390px;

		img {
			width: 100%;
		}
	}

	.about-flexbox {
		display: flex;
		justify-content: flex-end;

		.flexbox-description {
			width: 60%;

			h2 {
				color: #fff;
				font-size: 37px;
				text-transform: uppercase;
				letter-spacing: 12px;
				font-weight: 600;
				line-height: 50px;
			}

			h3 {
				margin-top: 10px;
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				display: block;

				&:after {
					margin-top: 15px;
					margin-bottom: 40px;
					content: '';
					display: block;
					width: 100px;
					height: 1px;
					background: rgba(255, 255, 255, 0.8);
				}
			}

			p {
				width: 70%;
				margin-top: 15px;
				font-weight: 300;
				color: #fff;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 1px;
			}

			ol, ul {
				padding-left: 17px;

				li {
					font-weight: 300;
					color: #fff;
					font-size: 14px;
					line-height: 26px;
					letter-spacing: 1px;
				}
			}

			.btn {
				margin-top: 30px;
			}
		}
	}

	&.white {
		background: #fff;

		h2, span, p, h3 {
			color: #434343 !important;
		}

		h3:after {
			background: rgba(0, 0, 0, 0.3) !important;
		}
	}
}

section.oferta-section-gallery {
	padding-top: 60px;
	padding-bottom: 80px;
	background: url(../img/backgrounds/offer-background.jpg) center bottom;
	background-size: cover;

	&.white {
		padding-top: 0;
		background: #fff;
	}
}

.gallery-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	a {
		margin-top: 2.5%;
		width: 18%;
		padding-top: 18%;
		overflow: hidden;
		position: relative;

		img {
			position: absolute;
			top: 0;
			left: 0;
		} 
	}

	.box-hidden {
		margin: 0;
		padding: 0;
		width: 18%;
		opacity: 0;
		visibility: hidden;
	}
}

section.realizacje-section-content {
	padding-top: 100px;
	padding-bottom: 80px;

	.section-heading {
		h1 {
			color: #434343;
			font-size: 37px;
			text-transform: uppercase;
			letter-spacing: 12px;
			font-weight: 600;
			line-height: 50px;
		}

		h2 {
			margin-top: 10px;
			text-transform: uppercase;
			color: #434343;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
			display: block;

			&:after {
				margin-top: 15px;
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				background: rgba(0, 0, 0, 0.3);
			}
		}

		p {
			width: 70%;
			margin-top: 15px;
			font-weight: 300;
			color: #777;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 1px;
		}
	}

	.content-category {
		margin-top: 40px;
		display: flex;
		align-items: center;

		p {
			text-transform: uppercase;
			color: #434343;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
			margin-right: 30px;
		}

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			li {
				margin: 5px 15px 5px 0;

				a, button {
					padding: 7px 18px;
					border: 1px solid #333;
					color: #333;
					font-size: 12px;
					display: block;
					background: #fff;
					transition: all 0.4s;
					white-space: nowrap;
					cursor: pointer;

					&.active, &:hover {
						background: @color1;
						border-color: @color1;
						color: #fff;
					}
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-body {
			width: 65%;

			.flexbox-news {
				margin-top: 60px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.news-service {
					margin-top: 6%;
					height: 300px;
					width: 47%;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					transition: all 0.3s;

					&:nth-child(1), &:nth-child(2) {
						margin-top: 0;
					}

					.service-inner {
						width: 90%;
						padding: 15px;
						padding-left: 0;
						min-height: 80px;
						background: #fff;

						h3 {
							color: #333;
							font-size: 14px;
							text-transform: uppercase;
							letter-spacing: 3px;
							font-weight: 600;
						}
					}

					&:hover {
						opacity: 0.7;
					}
				}
			}
		}

		.flexbox-sidebar {
			margin-top: 60px;
			width: 25%;

			.sidebar-heading {
				transform: translateY(-3px);
				text-transform: uppercase;
				color: #434343;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
				margin-right: 30px;
			}

			.sidebar-cooperate {
				margin-top: 20px;
				position: relative;
				width: 100%;

				.cooperate-inner {
					display: flex;
					justify-content: space-between;

					.inner-service {
						padding-top: 20%;
						width: 45%;
						display: block;
						background: #333;
						box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.15);
						transition: all 0.3s;

						&:hover {
							box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.22);
						}
					}
				}
			}

			.description {
				margin-top: 50px;

				.text {
					p {
						margin-top: 15px;
						font-weight: 300;
						color: #777;
						font-size: 14px;
						line-height: 26px;
						letter-spacing: 1px;
					}
				}
			}

			.contact {
				margin-top: 50px;

				.sidebar-heading {
					margin-bottom: 15px;
				}

				a {
					text-transform: uppercase;
					color: #777;
					font-size: 17px;
					font-weight: 700;
					transition: all 0.4s;

					&:hover {
						color: @color1;
					}
				}
			}
		}
	}

	.pagination-nav {
		li {
			a {
				color: #434343;

				&:hover {
					color: @color1;
				}
			}

			&.active {
				a {
					color: @color1;
				}
			}
		}
	}
}

section.wpis-section-content {
	background: #232323;
	padding-top: 100px;
	padding-bottom: 40px;

	.section-heading {
		h1 {
			color: transparent;
			font-size: 37px;
			text-transform: uppercase;
			letter-spacing: 12px;
			font-weight: 600;
			line-height: 50px;
			position: relative;
			z-index: 111;

			a {
				color: inherit;
				display: block;

				&:before {
					content: 'TEMAT';
					transition: 0.4s;
					position: absolute;
					color: #fff;
					top: 0;
					cursor: pointer;
				}

				&:after {
					content: 'WSTECZ';
					position: absolute;
					opacity: 0;
					visibility: hidden;
					left: 0;
					color: #fff;
					transition: all 0.4s;
					bottom: -35px;
					cursor: pointer;
				}

				&:hover {
					&:before {
						opacity: 0;
						top: -35px;
					}

					&:after {
						opacity: 1;
						bottom: 0;
						visibility: visible;
					}
				}
			}
		}

		span {
			margin-top: 10px;
			text-transform: uppercase;
			color: #fff;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
			display: block;

			&:after {
				margin-top: 15px;
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				background: rgba(255, 255, 255, 0.8);
			}
		}
	}

	.content-flexbox {
		margin-top: 80px;
		display: flex;
		justify-content: space-between;
		width: 90%;

		.flexbox-sidebar {
			width: 40%;

			img {
				width: 100%;
			}

			.sidebar-gallery {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				a {
					margin-top: 5%;
					width: 30%;
					padding-top: 30%;
					overflow: hidden;
					position: relative;

					img {
						position: absolute;
						top: 0;
						left: 0;
					} 
				}

				.box-hidden {
					margin: 0;
					padding: 0;
					width: 30%;
					opacity: 0;
					visibility: hidden;
				}
			}
		}

		.flexbox-body {
			width: 55%;
			transform: translateY(-5px);

			h2, h3 {
				text-transform: uppercase;
				color: #fff;
				letter-spacing: 5px;
				font-size: 17px;
				font-weight: 300;
			}

			> * {
				margin-top: 15px;

				&:first-child {
					margin-top: 0;
				}
			}

			p, ol, ul {
				font-weight: 300;
				color: #fff;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 1px;
			}
		}
	}

	&.realizacja {
		padding-bottom: 80px;
		background: #fff;

		.section-heading {
			h1 {
				a {
					&:before {
						content: 'REALIZACJA';
						color: #434343;
					}

					&:after {
						color: #434343;
					}
				}
			}

			span {
				color: #434343;

				&:after {
					background: rgba(0, 0, 0, 0.3);
				}
			}
		}

		.content-flexbox {
			.flexbox-body {
				h2, h3 {
					color: #434343;
				}

				p, ol, ul {
					color: #434343;
				}
			}
		}
	}
}

section.kontakt-section-content {
	.section-heading {
		padding-top: 100px;

		h1 {
			color: #434343;
			font-size: 37px;
			text-transform: uppercase;
			letter-spacing: 10px;
			font-weight: 600;
			line-height: 50px;
		}

		h2 {
			margin-top: 10px;
			text-transform: uppercase;
			color: #434343;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
			display: block;

			&:after {
				margin-top: 15px;
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				background: rgba(0, 0, 0, 0.3);
			}
		}
	}

	.content-contact-details {
		margin-top: 80px;
		position: relative;

		.details-background-color{
			position: absolute;
			z-index: -1;
			width: 45%;
			height: 100%;
			top: 0;
			left: 0;
			background: @color1;
		}

		.details-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 80px 0;

			.info-iframe {
				width: 50%;
				
				iframe {
					height: 100%;
					min-height: 400px;
					width: 100%;
					border: none;
				}
			}

			.info-description {
				width: 40%;

				h3 {
					color: #333;
					font-size: 28px;
					text-transform: uppercase;
					letter-spacing: 5px;
					font-weight: 600;
					margin-bottom: 10px;
				}

				p {
					font-size: 18px;
					line-height: 25px;
					margin-bottom: 20px;
				}

				.details-list {
					list-style: none;

					.list-item {
						padding: 8px 0;

						span {
							font-weight: 700;
							margin-bottom: 5px;
						}

						a {
							text-decoration: none;
							color: #333;
						}
					}
				}
			}

		}
	}
}

section.static-page-section-content {
	padding: 100px 0 80px 0;

	.section-heading {
		h1 {
			color: #434343;
			font-size: 37px;
			text-transform: uppercase;
			letter-spacing: 12px;
			font-weight: 600;
			line-height: 50px;

			&:after {
				margin-top: 15px;
				content: '';
				display: block;
				width: 100px;
				height: 1px;
				background: rgba(0, 0, 0, 0.3);
			}
		}
	}

	.content-inner {
		margin-top: 50px;
		width: 70%;

		h2, h3 {
			text-transform: uppercase;
			color: #434343;
			letter-spacing: 5px;
			font-size: 17px;
			font-weight: 300;
		}

		> * {
			margin-top: 15px;

			&:first-child {
				margin-top: 0;
			}
		}

		p, ol, ul {
			font-weight: 300;
			color: #434343;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: 1px;
		}
	}
}

section.section-footer {
	padding: 50px 0;
	background: #232323;
	position: relative;
	overflow: hidden;

	.footer-logo {
		display: flex;
		justify-content: center;

		img {
			max-width: 100%;
		}
	}

	.footer-flexbox {
		display: flex;
		justify-content: space-between;
		position: relative;

		.flexbox-service {
			margin-top: 50px;

			p {
				margin-top: 10px;
				font-size: 14px;
				font-weight: 100;
				color: #e6e6e5;
			}

			ul {
				li {
					margin-top: 10px;

					&.heading {
						margin-top: 0;
						font-weight: 600;
						padding-bottom: 10px;
						font-size: 16px;
						color: #e6e6e5;
					}

					a {
						font-size: 12px;
						color: #e6e6e5;
						transition: all 0.3s;

						b {
							font-size: 16px;
						}

						span {
							font-size: 10px;
						}

						&:hover {
						}
					}

					p {
						font-size: 12px;
						color: #e6e6e5;

						b {
							font-weight: 400;
							font-size: 14px;
						}

						span {
							font-size: 10px;
						}
					}
				}
			}

			.list-social {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				li {
					margin-right: 15px;

					a {
						img {
							height: 25px;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.footer-credits {
		border-top: 1px solid rgba(255, 255, 255, 0.3);
		margin-top: 50px;
		padding-top: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		p {
			font-size: 12px;
			color: #e6e6e5;
			font-weight: 300;

			a {
				color: #e6e6e5;
			}
		}
	}

	.alert {
		position: relative;
		margin: 50px 0 0 0;
		padding: 50px 0 0 0;
		border-radius: 0;
		background: none;
		border: none;
		border-top: 1px solid rgba(255, 255, 255, 0.3);

		p {
			font-size: 12px;
			color: #e6e6e5;
			font-weight: 300;

			a {
				color: #e6e6e5;
				cursor: pointer;
			}
		}
	}
}